import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaEdit, FaTrash } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import CrewEditModal from './CrewEditModal';
import CrewItem from './CrewItem';
import axios from 'axios';

const CrewList = ({ crews, isLoadingCrews, expandedCrew, toggleCrewMembers, fetchCrews }) => {
  const [editingCrew, setEditingCrew] = useState(null);
  const [deletingCrew, setDeletingCrew] = useState(null);

  const handleEditCrew = (crew) => {
    setEditingCrew(crew);
  };

  const handleDeleteCrew = (crew) => {
    setDeletingCrew(crew);
  };

  const closeEditModal = () => {
    setEditingCrew(null);
  };

  const confirmDeleteCrew = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Usuário não autenticado.');
        return;
      }

      const response = await axios.delete(`${API_URL}/api/group/${deletingCrew._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        fetchCrews(); // Atualiza a lista de Crews
        setDeletingCrew(null);
      }
    } catch (error) {
      console.error('Erro ao deletar a Crew:', error.response || error.message);
    }
  };

  return (
    <div className="bg-[#1C1C1C] p-6 rounded-lg shadow-lg">
      <div className="crew-list space-y-6">
        {isLoadingCrews ? (
          <Skeleton count={3} height={120} />
        ) : (
          crews.map((crew) => (
            <CrewItem
              key={crew._id}
              crew={crew}
              expandedCrew={expandedCrew}
              toggleCrewMembers={toggleCrewMembers}
              handleEditCrew={handleEditCrew}
              handleDeleteCrew={handleDeleteCrew}
            />
          ))
        )}
      </div>
      {editingCrew && (
        <CrewEditModal
          crew={editingCrew}
          onClose={closeEditModal}
          fetchCrews={fetchCrews}
        />
      )}
      {deletingCrew && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-[#181818] p-6 rounded-lg shadow-lg w-full max-w-sm">
            <h3 className="text-xl font-bold mb-4 text-[#1DB954]">Confirmar Remoção</h3>
            <p className="text-gray-300 mb-6">Tem certeza de que deseja remover a Crew "{deletingCrew.name}"?</p>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-500 transition"
                onClick={() => setDeletingCrew(null)}
              >
                Cancelar
              </button>
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-500 transition"
                onClick={confirmDeleteCrew}
              >
                Remover
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrewList;
