// App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Homepage from './components/Homepage';
import MintNFT from './components/MintNFT';
import MusicNFTList from './components/MusicNFTList';
import NFTDetails from './components/NFTDetails';
import EventHistory from './components/EventHistory';
import Inbox from './components/Inbox';
import EmailDetailsPage from './components/inbox-module/EmailDetailsPage';
import Crew from './components/Crew';
import MintAccessKey from './components/MintAccessKey';
import Discover from './components/Discover';
import ProfileManager from './components/ProfileManager';
import Chat from './components/Chat';
import UserProfile from './components/UserProfile';
import Footer from './components/Footer';
import { Web3Provider, Web3Context } from './context/Web3Context';
import { MusicPlayerProvider } from './context/MusicPlayerContext';
import MusicPlayer from './components/MusicPlayer';

function App() {
  return (
    <MusicPlayerProvider>
      <Router>
        <Web3Provider>
          <div className="App">
            <Header />
            <div id="main-content" className="pt-20">
              <MainContent />
            </div>
            <MusicPlayer />
          </div>
        </Web3Provider>
      </Router>
    </MusicPlayerProvider>
  );
}

function MainContent() {
  const { currentAccount } = useContext(Web3Context);

  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        {currentAccount ? (
          <>
          
            <Route path="/discover" element={<Discover />} />
            <Route path="/mint" element={<MintNFT />} />
            <Route path="/nfts" element={<MusicNFTList />} />
            <Route path="/details" element={<NFTDetails />} />
            <Route path="/crew" element={<Crew />} />
            <Route path="/profilemanager" element={<ProfileManager />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/events" element={<EventHistory />} />
            <Route path="/login" element={<Homepage />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/keypass" element={<MintAccessKey />} />
            <Route path="/profile/:wallet" element={<UserProfile />} />
            <Route path="/email/:emailId" element={<EmailDetailsPage />} />
          </>
        ) : (
          <Route
            path="*"
            element={
              <div className="flex justify-center items-center h-screen">
                <p className="text-2xl font-bold text-red-600">
                  Por favor, conecte-se à MetaMask para acessar a plataforma.
                </p>
              </div>
            }
          />
        )}
      </Routes>
      <Footer />
    </>
  );
}


export default App;
