import { ReactSVG } from 'react-svg';

const Logo = ({ color = "#1DB954", width = "100px", height = "auto" }) => (
  <ReactSVG
    src={`${process.env.PUBLIC_URL}/logo.svg`}
    beforeInjection={(svg) => {
      svg.setAttribute('fill', color); // Altera a cor
      svg.setAttribute('width', '100%');
      svg.setAttribute('height', '100%');
    }}
    style={{ width, height }} // Controla o tamanho com estilo inline
  />
);

export default Logo;
