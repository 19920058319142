import React, { useMemo } from 'react';

const TraitFilter = ({ nfts, selectedTraits, onSelect, onClear }) => {
  // Memoriza o cálculo dos atributos únicos
  const traits = useMemo(() => {
    const traitMap = {};

    nfts.forEach((nft) => {
      const attributes = nft.metadata?.attributes || [];
      attributes.forEach((attr) => {
        const { trait_type, value } = attr;
        if (trait_type && value) {
          if (!traitMap[trait_type]) {
            traitMap[trait_type] = new Set();
          }
          traitMap[trait_type].add(value);
        }
      });
    });

    return traitMap;
  }, [nfts]);

  return (
    <div
      className="mb-6 p-6 rounded-lg bg-[#121212] border border-[#282828] text-white"
    >
      <h3 className="text-xl font-semibold mb-4">Filtrar por Atributos</h3>
      <div>
        {Object.entries(traits).map(([traitType, values]) => (
          <div key={traitType} className="mb-4">
            <h4 className="font-semibold text-[#1DB954]">{traitType}</h4>
            <div className="flex flex-wrap gap-2 mt-2">
              {[...values].map((value) => {
                const key = `${traitType}:${value}`;
                const isSelected = selectedTraits.includes(key);

                return (
                  <label
                    key={key}
                    className={`flex items-center space-x-2 px-3 py-1 rounded-md transition-all duration-200 cursor-pointer ${
                      isSelected
                        ? 'bg-[#1DB954] text-black'
                        : 'bg-[#282828] text-white hover:bg-[#3E3E3E]'
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={isSelected}
                      onChange={() => onSelect(key)}
                      className="form-checkbox text-[#1DB954] bg-transparent rounded-md"
                      aria-label={`${traitType}: ${value}`}
                    />
                    <span>{value}</span>
                  </label>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {selectedTraits.length > 0 && (
        <button
          className="mt-4 px-4 py-2 rounded-lg text-white font-semibold transition-all duration-300 bg-[#1DB954] hover:bg-[#1ED760]"
          onClick={onClear}
        >
          Limpar Filtros
        </button>
      )}
    </div>
  );
};

export default TraitFilter;