import React from 'react';
import { FaUpload, FaCamera } from 'react-icons/fa';

const PersonalInfo = ({
  activeTab,
  formData,
  handleInputChange,
  handleFileChange,
  previewProfilePicture,
  previewBackgroundPicture, // Adicionado para preview do background
  previewBanner,
  handleSocialLinkChange,
  handlePreferenceChange,
  addNewGenre,
  removeGenre,
  handleArtistToggle,
}) => {
  return (
    <div className="p-6 bg-[#121212] bg-opacity-95 rounded-lg shadow-2xl transition-all duration-300">
      {activeTab === 'info' && (
        <div className="p-6 bg-gradient-to-r from-[#282828] to-[#121212] rounded-lg shadow-inner mb-8">
          <h3 className="text-2xl font-bold mb-6 text-[#1DB954] border-b border-gray-600 pb-2">Informações Pessoais</h3>

          <div className="flex flex-col lg:flex-row items-start gap-8 mb-8">
            {/* Avatar (Foto de Perfil) */}
            <div className="flex flex-col items-center w-36 h-36">
              <div
                className="relative w-full h-full rounded-full bg-[#333333] overflow-hidden shadow-md cursor-pointer group"
                onClick={() => document.getElementById('profilePictureInput').click()}
              >
                {previewProfilePicture ? (
                  <img
                    src={previewProfilePicture}
                    alt="Foto de Perfil"
                    className="object-cover w-full h-full rounded-full group-hover:opacity-75 transition-opacity duration-300"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-gray-400 group-hover:opacity-75 transition-opacity">
                    Nenhuma foto selecionada
                  </div>
                )}
                <input
                  type="file"
                  name="profilePicture"
                  onChange={handleFileChange}
                  className="hidden"
                  id="profilePictureInput"
                  accept="image/*"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
                  <FaCamera className="text-white text-3xl opacity-80" />
                </div>
              </div>
            </div>

            {/* Banner */}
            <div className="flex flex-col items-center w-full">
              <div
                className="relative w-full h-48 bg-[#333333] rounded-lg overflow-hidden shadow-md cursor-pointer group"
                onClick={() => document.getElementById('bannerInput').click()}
              >
                {previewBanner ? (
                  <img
                    src={previewBanner}
                    alt="Banner do Perfil"
                    className="object-cover w-full h-full group-hover:opacity-75 transition-opacity duration-300"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center text-gray-400 group-hover:opacity-75 transition-opacity">
                    Nenhum banner selecionado
                  </div>
                )}
                <input
                  type="file"
                  name="banner"
                  onChange={handleFileChange}
                  className="hidden"
                  id="bannerInput"
                  accept="image/*"
                />
                <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
                  <FaCamera className="text-white text-4xl opacity-80" />
                </div>
              </div>
            </div>
          
{/* Background Customizado */}
<div className="flex flex-col items-center w-full">
  <div
    className="relative w-full h-64 bg-[#333333] rounded-lg overflow-hidden shadow-md cursor-pointer group"
    onClick={() => document.getElementById('backgroundPictureInput').click()}
  >
    {previewBackgroundPicture ? (
      <img
        src={previewBackgroundPicture}
        alt="Background do Perfil"
        className="object-cover w-full h-full group-hover:opacity-75 transition-opacity duration-300"
      />
    ) : (
      <div className="w-full h-full flex items-center justify-center text-gray-400 group-hover:opacity-75 transition-opacity">
        Nenhum background selecionado
      </div>
    )}
    <input
      type="file"
      name="backgroundPicture"
      onChange={handleFileChange}
      className="hidden"
      id="backgroundPictureInput"
      accept="image/*"
    />
    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
      <FaUpload className="text-white text-4xl opacity-80" />
    </div>
  </div>
</div>


          </div>

          {/* Detalhes do Perfil */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <label className="block text-md font-semibold mb-2 text-gray-300">Nome Completo</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              />
            </div>

            <div>
              <label className="block text-md font-semibold mb-2 text-gray-300">Nome de Usuário</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              />
            </div>
          </div>

          {/* Email e Localização */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            <div>
              <label className="block text-md font-semibold mb-2 text-gray-300">E-mail</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              />
            </div>

            <div>
              <label className="block text-md font-semibold mb-2 text-gray-300">Localização</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              />
            </div>
          </div>

          {/* Bio */}
          <div className="mt-4">
            <label className="block text-md font-semibold mb-2 text-gray-300">Bio</label>
            <textarea
              name="bio"
              value={formData.bio}
              onChange={handleInputChange}
              className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              rows="4"
            ></textarea>
          </div>
        </div>
      )}

      {/* Aba de Redes Sociais */}
      {activeTab === 'social' && (
        <div className="p-6 bg-gradient-to-r from-[#282828] to-[#121212] rounded-lg shadow-inner mb-8">
          <h3 className="text-2xl font-bold mb-6 text-[#1DB954] border-b border-gray-600 pb-2">Redes Sociais</h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {['twitter', 'soundcloud', 'discord', 'instagram', 'facebook'].map((platform) => (
              <div key={platform}>
                <label className="block text-md font-semibold mb-2 text-gray-300">
                  {platform.charAt(0).toUpperCase() + platform.slice(1)}
                </label>
                <input
                  type="text"
                  name={platform}
                  value={formData.socialLinks[platform]}
                  onChange={handleSocialLinkChange}
                  className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Preferências Musicais */}
      {activeTab === 'preferences' && (
        <div className="p-6 bg-gradient-to-r from-[#282828] to-[#121212] rounded-lg shadow-inner mb-8">
          <h3 className="text-2xl font-bold mb-6 text-[#1DB954] border-b border-gray-600 pb-2">Preferências Musicais</h3>
          {formData.preferences.genres.map((genre, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={genre}
                onChange={(e) => handlePreferenceChange(index, e.target.value)}
                className="w-full p-3 rounded-lg bg-[#333333] text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1DB954] shadow-inner transition duration-300"
              />
              <button
                type="button"
                className="bg-red-600 px-2 py-1 rounded text-white hover:bg-red-700 transition duration-300"
                onClick={() => removeGenre(index)}
              >
                Remover
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addNewGenre}
            className="mt-2 bg-[#1DB954] px-4 py-2 rounded-lg text-black hover:bg-[#1AAE44] transition duration-300"
          >
            Adicionar Gênero
          </button>
        </div>
      )}

      {/* Configurações de Artista */}
      {activeTab === 'artist' && (
        <div className="p-6 bg-gradient-to-r from-[#282828] to-[#121212] rounded-lg shadow-inner mb-8">
          <h3 className="text-2xl font-bold mb-6 text-[#1DB954] border-b border-gray-600 pb-2">Configurações de Artista</h3>
          <div className="flex items-center space-x-4">
            <label className="block text-md font-semibold text-gray-300">Artista?</label>
            <input
              type="checkbox"
              checked={formData.isArtist}
              onChange={handleArtistToggle}
              className="w-6 h-6 text-[#1DB954] focus:ring-2 focus:ring-[#1DB954] transition duration-300"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalInfo;