import React from 'react';
import { motion } from 'framer-motion';
import { FaCrown, FaCheckCircle } from 'react-icons/fa';

const ProfileHeader = ({ userProfile }) => (
  
  
  <motion.div 
    className="relative bg-[#121212] border border-[#282828] rounded-lg shadow-lg overflow-hidden mb-8 p-6"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <div 
      className="absolute inset-0 bg-cover bg-center opacity-20" 
      style={{
        backgroundImage: `url(${userProfile.banner || 'https://via.placeholder.com/1200x400'})`,
        filter: 'blur(2px)',
        zIndex: 0,
      }}
    />
    <div className="relative flex flex-col lg:flex-row items-center" style={{ zIndex: 1 }}>
      <motion.div
        className="w-32 h-32 lg:w-40 lg:h-40 rounded-full overflow-hidden border-4 border-[#1DB954] shadow-lg cursor-pointer"
        whileHover={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <img
          src={userProfile.profilePicture || 'https://via.placeholder.com/150'}
          alt="Foto de perfil"
          className="object-cover w-full h-full"
        />
      </motion.div>
      <div className="lg:ml-6 mt-4 lg:mt-0 text-center lg:text-left flex flex-col items-center lg:items-start">
        <h1 className="text-2xl lg:text-3xl font-semibold text-[#1DB954] tracking-wide flex items-center">
          {userProfile.username}
          {userProfile.isArtist && (
            <FaCheckCircle className="text-blue-500 ml-2" title="Artista Verificado" />
          )}
        </h1>
        <div className="flex justify-center lg:justify-start space-x-2 mt-2 items-center">
          {userProfile.role === 'ADMIN' && <FaCrown className="text-yellow-400" />}
          <span className="text-xs px-2 py-1 rounded-full bg-gradient-to-r from-green-500 to-green-700 text-white shadow-lg">{userProfile.role}</span>
        </div>
        <p className="text-gray-300 mt-2 text-center lg:text-left">{userProfile.bio}</p>
        <p className="text-sm text-gray-400">{userProfile.fullName} - {userProfile.location}</p>
      </div>
    </div>
  </motion.div>
);

export default ProfileHeader;
