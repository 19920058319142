// ProfileManager.js
import React, { useState, useContext, useEffect } from 'react';
import { Web3Context } from '../context/Web3Context';
import PersonalInfo from './PersonalInfo';
import { PulseLoader } from 'react-spinners';

const ProfileManager = () => {
  const { currentAccount, userProfile, updateUserProfile } = useContext(Web3Context);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    fullName: '',
    bio: '',
    location: '',
    socialLinks: {
      twitter: '',
      soundcloud: '',
      discord: '',
      instagram: '',
      facebook: '',
    },
    preferences: {
      genres: [''],
    },
    backgroundPicture: null, // Arquivo de imagem de perfil
    profilePictureFile: null, // Arquivo de imagem de perfil
    bannerFile: null,         // Arquivo de banner
    isArtist: false,
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [previewProfilePicture, setPreviewProfilePicture] = useState(null);
  const [previewBackgroundPicture, setPreviewBackgroundPicture] = useState(null);
  const [previewBanner, setPreviewBanner] = useState(null);
  const [activeTab, setActiveTab] = useState('info');

  useEffect(() => {
    if (userProfile) {
      setFormData({
        ...userProfile,
        profilePictureFile: null,
        previewBackgroundPicture: null,
        bannerFile: null,
      });
      setPreviewBackgroundPicture(userProfile.backgroundPicture);
      setPreviewProfilePicture(userProfile.profilePicture);
      setPreviewBanner(userProfile.banner);
    }
  }, [userProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      socialLinks: {
        ...formData.socialLinks,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === 'profilePicture') {
        setPreviewProfilePicture(URL.createObjectURL(file));
        setFormData((prevState) => ({ ...prevState, profilePictureFile: file }));
      } else if (name === 'banner') {
        setPreviewBanner(URL.createObjectURL(file));
        setFormData((prevState) => ({ ...prevState, bannerFile: file }));
      } else if (name === 'backgroundPicture') {
        setPreviewBackgroundPicture(URL.createObjectURL(file)); // Adicionar preview
        setFormData((prevState) => ({ ...prevState, backgroundPictureFile: file })); // Adicionar ao formData
      }
    }
  };
  
  

  const handleArtistToggle = () => {
    setFormData({ ...formData, isArtist: !formData.isArtist });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('username', formData.username);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('fullName', formData.fullName);
      formDataToSend.append('bio', formData.bio);
      formDataToSend.append('location', formData.location);
      formDataToSend.append('isArtist', formData.isArtist);
  
      // Adiciona redes sociais e gêneros musicais
      Object.keys(formData.socialLinks).forEach((key) => {
        formDataToSend.append(`socialLinks[${key}]`, formData.socialLinks[key]);
      });
      formData.preferences.genres.forEach((genre, index) => {
        formDataToSend.append(`preferences.genres[${index}]`, genre);
      });
  
      // Adiciona arquivos de imagem se forem selecionados
      if (formData.profilePictureFile) {
        formDataToSend.append('profilePicture', formData.profilePictureFile);
      }
      if (formData.bannerFile) {
        formDataToSend.append('banner', formData.bannerFile);
      }
      if (formData.backgroundPictureFile) { // Verifica e adiciona o backgroundPicture
        formDataToSend.append('backgroundPicture', formData.backgroundPictureFile);
      }
  
      // Enviar ao backend com headers
      const authToken = localStorage.getItem('authToken');
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
  
      await updateUserProfile(formDataToSend, headers); // Passar o FormData
      
      setSuccess(true);
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Erro ao atualizar o perfil:', error);
      setSuccess(false);
      setTimeout(() => setSuccess(null), 3000);
    }
    setLoading(false);
  };
  

  return (
    <div className="min-h-screen p-8 text-white bg-[#121212] flex">
      {/* Menu Lateral */}
      <div className="w-1/4 bg-[#181818] p-6 rounded-lg shadow-lg flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-6 text-[#1DB954]">Menu</h2>
          <ul className="space-y-4">
            {['info', 'social', 'preferences', 'artist'].map((tab) => (
              <li key={tab}>
                <button
                  className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 ${
                    activeTab === tab ? 'bg-[#1DB954] text-black' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab === 'info' && 'Informações Pessoais'}
                  {tab === 'social' && 'Redes Sociais'}
                  {tab === 'preferences' && 'Preferências Musicais'}
                  {tab === 'artist' && 'Configurações de Artista'}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <button
          type="submit"
          onClick={handleSubmit}
          className={`mt-6 bg-[#1DB954] p-3 rounded-lg text-black flex items-center justify-center shadow-lg transform transition-transform duration-300 hover:scale-105 ${
            loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-700'
          }`}
          disabled={loading}
        >
          {loading ? <PulseLoader size={8} color="#fff" /> : 'Salvar Perfil'}
        </button>
      </div>

      {/* Área de Conteúdo */}
      <div className="w-3/4 ml-8 bg-[#181818] p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6 text-[#1DB954] text-center">Gerenciar Perfil</h2>

        <form className="space-y-8">
          <PersonalInfo
            activeTab={activeTab}
            formData={formData}
            handleInputChange={handleInputChange}
            handleFileChange={handleFileChange}
            previewProfilePicture={previewProfilePicture}
            previewBanner={previewBanner}
            previewBackgroundPicture={previewBackgroundPicture} // Passando o preview do background para PersonalInfo
            handleSocialLinkChange={handleSocialLinkChange}
            handleArtistToggle={handleArtistToggle}
            handlePreferenceChange={(index, value) => {
              const updatedGenres = [...formData.preferences.genres];
              updatedGenres[index] = value;
              setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  genres: updatedGenres,
                },
              });
            }}
            addNewGenre={() => {
              setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  genres: [...formData.preferences.genres, ''],
                },
              });
            }}
            removeGenre={(index) => {
              const updatedGenres = formData.preferences.genres.filter((_, i) => i !== index);
              setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  genres: updatedGenres,
                },
              });
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default ProfileManager;
