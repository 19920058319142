import React, { useState, useContext } from 'react';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/MusicNFT';

const NFTDetails = () => {
  const { provider } = useContext(Web3Context);
  const [tokenId, setTokenId] = useState('');
  const [owner, setOwner] = useState('');
  const [tokenURI, setTokenURI] = useState('');
  const [metadata, setMetadata] = useState(null);

  const fetchNFTDetails = async () => {
    const tokenIdNumber = parseInt(tokenId, 10);
    if (provider && !isNaN(tokenIdNumber)) {
      const contract = getContract(provider);
      try {
        console.log('Consultando tokenId:', tokenIdNumber);

        // Obter proprietário e URI do token
        const _owner = await contract.ownerOf(tokenIdNumber);
        const _tokenURI = await contract.tokenURI(tokenIdNumber);

        console.log('Proprietário:', _owner);
        console.log('Token URI retornado:', _tokenURI); // Verifique se o tokenURI está correto

        setOwner(_owner);
        setTokenURI(_tokenURI);

        // Fazer a requisição HTTP para buscar os metadados do JSON
        const response = await fetch(_tokenURI);
        if (!response.ok) {
          throw new Error('Erro ao buscar os metadados: ' + response.statusText);
        }

        const jsonMetadata = await response.json();
        console.log('Metadados retornados:', jsonMetadata); // Verifique os metadados

        // Salvar os metadados no estado
        setMetadata(jsonMetadata);
      } catch (error) {
        console.error('Erro ao obter detalhes do NFT:', error);
        alert('Erro ao obter detalhes do NFT: ' + error.message);
      }
    } else {
      alert('Por favor, conecte sua carteira e insira um Token ID válido.');
    }
  };

  return (
    <div>
      <h2>Detalhes do NFT</h2>
      <input
        type="number"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
      />
      <button onClick={fetchNFTDetails}>Buscar Detalhes</button>

      {owner && (
        <div>
          <p>Proprietário: {owner}</p>
          <p>Token URI: {tokenURI}</p>
        </div>
      )}

      {metadata && (
        <div>
          <h3>Metadados do NFT:</h3>
          <p><strong>Nome:</strong> {metadata.name}</p>
          <p><strong>Descrição:</strong> {metadata.description}</p>
          <img src={metadata.image} alt={metadata.name} width="200" />
          {/* Renderize mais atributos conforme necessário */}
        </div>
      )}
    </div>
  );
};

export default NFTDetails;
