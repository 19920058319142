import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const EmailDetailsPage = () => {
  const { emailId } = useParams(); // Pega o ID do e-mail a partir da URL
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/emails/${emailId}`);
        setEmail(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar o e-mail:', error);
        setLoading(false);
      }
    };
    fetchEmail();
  }, [emailId]);

  if (loading) {
    return <p>Carregando e-mail...</p>;
  }

  if (!email) {
    return <p>Não foi possível encontrar o e-mail.</p>;
  }

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-800 text-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-green-400 mb-2">{email.subject || 'Sem título'}</h2>
      <p className="text-gray-300 mb-4">{email.body || 'Sem conteúdo'}</p>
      <p className="text-xs text-gray-500">Enviado por: {email.sender || 'Desconhecido'}</p>
      <p className="text-xs text-gray-500">
        Data: {email.createdAt ? new Date(email.createdAt).toLocaleString() : 'Data inválida'}
      </p>
    </div>
  );
};

export default EmailDetailsPage;
