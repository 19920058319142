// TransferModal.js
import React from 'react';

const TransferModal = ({
  selectedNFT,
  transferAddress,
  setTransferAddress,
  handleTransferNFT,
  closeModal,
  isTransferring,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-80">
        <h3 className="text-2xl font-bold text-green-500 mb-4">Transferir NFT Selecionado</h3>
        <p className="text-gray-400 mb-2">Token ID: {selectedNFT.tokenId}</p>
        <input
          type="text"
          value={transferAddress}
          onChange={(e) => setTransferAddress(e.target.value)}
          placeholder="Endereço da carteira de destino"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4 focus:outline-none"
        />
        <button
          onClick={handleTransferNFT}
          className={`bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-300 w-full ${
            isTransferring ? 'opacity-50' : ''
          }`}
          disabled={isTransferring}
        >
          {isTransferring ? 'Transferindo...' : 'Transferir NFT'}
        </button>
        <button
          onClick={closeModal}
          className="mt-2 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg transition duration-300 w-full"
        >
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default TransferModal;
