import React, { useState, useContext } from 'react';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/MusicNFT';
import { ethers } from 'ethers';
import AWS from 'aws-sdk';

const MintNFT = () => {
  const { currentAccount } = useContext(Web3Context);
  const [metadata, setMetadata] = useState({
    name: '',
    description: '',
    image: '',
    audioToken: '',
    attributes: [],
  });
  const [audioCID, setAudioCID] = useState('');
  const [isMinting, setIsMinting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMetadata({ ...metadata, [name]: value });
  };

  const handleAddAttribute = () => {
    setMetadata({
      ...metadata,
      attributes: [...metadata.attributes, { trait_type: '', value: '' }],
    });
  };

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...metadata.attributes];
    updatedAttributes[index][field] = value;
    setMetadata({ ...metadata, attributes: updatedAttributes });
  };

  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const s3 = new AWS.S3({
      endpoint: 'https://s3.filebase.com',
      region: 'us-east-1',
      signatureVersion: 'v4',
      accessKeyId: 'D60F626C3E851E60BBA5',
      secretAccessKey: 'CAQIWC7dU0gM7VEQIjAADnWyNUbfEpMmQ90OeBwl',
    });

    const params = {
      Bucket: 'mtda',
      Key: file.name,
      Body: file,
      ContentType: file.type,
    };

    try {
      const request = s3.putObject(params);
      request.on('httpHeaders', (statusCode, headers) => {
        if (statusCode === 200) {
          const cid = headers['x-amz-meta-cid'];
          if (cid) {
            setAudioCID(cid); // Salva o CID para uso posterior
            alert(`Upload bem-sucedido! CID: ${cid}`);
          } else {
            alert('Erro ao obter o CID do áudio.');
          }
        }
      });
      request.send();
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  };

  const checkAudioToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getAudio/check-token/${metadata.audioToken}`);
      if (!response.ok) {
        throw new Error('audioToken já existe. Escolha outro.');
      }
    } catch (error) {
      alert(error.message);
      throw error; // Para interromper o fluxo se o token já existir
    }
  };
  

  const handleMint = async (e) => {
    e.preventDefault();
    if (!currentAccount) {
      alert('Conecte sua carteira primeiro.');
      return;
    }

    if (!audioCID) {
      alert('Por favor, faça o upload do áudio antes de cunhar.');
      return;
    }

    try {
      setIsMinting(true);
      await checkAudioToken();

      // Criar metadata sem o filePath, apenas com o audioToken
      const metadataToUpload = {
        ...metadata,
        audioToken: metadata.audioToken // Inclui o audioToken no metadata do NFT
      };

      const s3 = new AWS.S3({
        endpoint: 'https://s3.filebase.com',
        region: 'us-east-1',
        signatureVersion: 'v4',
        accessKeyId: 'D60F626C3E851E60BBA5',
        secretAccessKey: 'CAQIWC7dU0gM7VEQIjAADnWyNUbfEpMmQ90OeBwl',
      });

      const jsonMetadata = JSON.stringify(metadataToUpload);
      const blob = new Blob([jsonMetadata], { type: 'application/json' });

      const params = {
        Bucket: 'mtda',
        Key: `${metadata.name}.json`,
        Body: blob,
        ContentType: 'application/json',
      };

      const request = s3.putObject(params);
      request.on('httpHeaders', (statusCode, headers) => {
        if (statusCode === 200) {
          const cid = headers['x-amz-meta-cid'];
          if (cid) {
            const metadataURI = `https://ipfs.filebase.io/ipfs/${cid}`;
            mintNFT(metadataURI);
          } else {
            alert('Erro ao obter o CID do metadado.');
          }
        }
      });

      request.send();
    } catch (error) {
      console.error('Erro ao cunhar NFT:', error);
    } finally {
      setIsMinting(false);
    }
  };

  const mintNFT = async (metadataURI) => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = getContract(signer);
  
      const tx = await contract.mint(metadataURI, { value: ethers.parseEther('0.005') });
      await tx.wait();
  
      // Salvar audioToken e filePath (link CID) no banco de dados após a cunhagem
      await fetch(`${process.env.REACT_APP_API_URL}/getAudio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          audioToken: metadata.audioToken, 
          filePath: `https://ipfs.filebase.io/ipfs/${audioCID}` 
        }),
      });
  
      alert('NFT cunhado e dados salvos com sucesso!');
    } catch (error) {
      console.error('Erro ao realizar mint:', error);
    }
  };
  

  return (
    <div className="max-w-3xl mx-auto bg-gray-900 p-8 rounded-lg shadow-md mt-10">
      <h2 className="text-3xl font-bold mb-8 text-center text-green-400">Cunhar um Novo NFT de Música</h2>
      
      <form onSubmit={handleMint} className="space-y-6">
        <input type="file" accept="audio/*" onChange={handleAudioUpload} className="border border-gray-600 bg-gray-800 text-gray-300 p-3 rounded-md w-full" required />
        <input type="text" name="name" value={metadata.name} onChange={handleInputChange} placeholder="Nome da Música" className="border border-gray-600 bg-gray-800 text-gray-300 p-3 rounded-md w-full" required />
        <textarea name="description" value={metadata.description} onChange={handleInputChange} placeholder="Descrição da Música" className="border border-gray-600 bg-gray-800 text-gray-300 p-3 rounded-md w-full" required />
        <input type="text" name="image" value={metadata.image} onChange={handleInputChange} placeholder="URL da Imagem" className="border border-gray-600 bg-gray-800 text-gray-300 p-3 rounded-md w-full" required />
        <input type="text" name="audioToken" value={metadata.audioToken} onChange={handleInputChange} placeholder="Token do Áudio" className="border border-gray-600 bg-gray-800 text-gray-300 p-3 rounded-md w-full" required />
        
        <div className="mt-4">
          <h3 className="text-lg text-gray-400">Atributos</h3>
          {metadata.attributes.map((attr, index) => (
            <div key={index} className="flex gap-2 mt-2">
              <input type="text" placeholder="Tipo do Atributo" value={attr.trait_type} onChange={(e) => handleAttributeChange(index, 'trait_type', e.target.value)} className="border border-gray-600 bg-gray-800 text-gray-300 p-2 rounded-md w-1/2" />
              <input type="text" placeholder="Valor" value={attr.value} onChange={(e) => handleAttributeChange(index, 'value', e.target.value)} className="border border-gray-600 bg-gray-800 text-gray-300 p-2 rounded-md w-1/2" />
            </div>
          ))}
          <button type="button" onClick={handleAddAttribute} className="mt-2 text-green-500 hover:underline">+ Adicionar Atributo</button>
        </div>

        <button type="button" onClick={() => setShowPreview(!showPreview)} className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300">
          {showPreview ? 'Esconder Preview' : 'Visualizar Preview'}
        </button>

        {showPreview && (
          <pre className="bg-gray-800 text-green-400 p-4 rounded-md mt-4">
            {JSON.stringify(metadata, null, 2)}
          </pre>
        )}

        <button type="submit" className={`w-full bg-green-500 text-white font-semibold py-3 px-4 rounded-lg hover:bg-green-600 transition duration-300 ${isMinting ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={isMinting}>
          {isMinting ? 'Cunhando NFT...' : 'Cunhar NFT (0.005 ETH)'}
        </button>
      </form>
    </div>
  );
};

export default MintNFT;
