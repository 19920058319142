import React, { useEffect, useState, useCallback, useMemo } from 'react';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { forbiddenWordsSet } from './utils/forbiddenWords';

const MessageList = ({ messages, currentAccount, handleUserClick, messageBoxRef }) => {
  const [userProfiles, setUserProfiles] = useState({});
  const API_URL = process.env.REACT_APP_API_URL;

  // Filtra mensagens usando o Set de palavras proibidas
  const filterMessage = useCallback((text) => {
    return text.split(' ').map(word => (
      forbiddenWordsSet.has(word.toLowerCase()) ? '****' : word
    )).join(' ');
  }, []);

  // Função para buscar perfil do usuário
  const fetchUserProfile = useCallback(async (walletAddress) => {
    if (!walletAddress) return;
    try {
      if (!userProfiles[walletAddress]) {
        const response = await axios.get(`${API_URL}/api/user/${walletAddress}`);
        setUserProfiles((prevProfiles) => ({
          ...prevProfiles,
          [walletAddress]: response.data,
        }));
      }
    } catch (error) {
      console.error('Erro ao carregar perfil do usuário:', error);
    }
  }, [userProfiles]);

  // Buscar perfis dos usuários nas mensagens
  useEffect(() => {
    const uniqueAddresses = [...new Set(messages.map(msg => {
      const match = msg.user.match(/\[(.*?)\]/);
      return match ? match[1] : null;
    }))];

    uniqueAddresses.forEach(fetchUserProfile);
  }, [messages, fetchUserProfile]);

  // Função para rolar suavemente até o final da lista de mensagens
  useEffect(() => {
    if (messageBoxRef?.current) {
      messageBoxRef.current.scrollTo({
        top: messageBoxRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]); // Sempre que as mensagens mudam, a rolagem acontece

  // Componente que renderiza a mensagem no estilo chatlog
  const MessageItem = ({ message, userProfile, isCurrentUser }) => {
    const filteredMessage = filterMessage(message.text);
    const timeAgo = formatDistanceToNow(new Date(message.timestamp), {
      addSuffix: true,
      locale: ptBR,
    });

    return (
      <div className="flex flex-col mb-4">
        <div className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'}`}>
          <div
            className="flex items-center p-4 rounded-lg"
            style={{
              maxWidth: '80%',
              background: isCurrentUser ? 'rgba(30, 215, 96, 0.1)' : 'rgba(40, 40, 40, 0.8)',
              backdropFilter: 'blur(6px)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
              transition: 'transform 0.2s ease-in-out',
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              maxWidth: '600px',
            }}
          >
            <div className={`flex items-start ${isCurrentUser ? 'flex-row-reverse' : 'flex-row'}`}>
              {!isCurrentUser && (
                <img
                  src={userProfile?.profilePicture || 'https://via.placeholder.com/40'}
                  alt="Avatar"
                  className="w-10 h-10 rounded-full mr-3 shadow-md hover:scale-105 transition-transform cursor-pointer"
                  onClick={() => handleUserClick(message.user)}
                  title={message.user}
                />
              )}
              <div className="flex flex-col">
                <span className={`text-sm ${isCurrentUser ? 'text-[#1DB954]' : 'text-[#1ED760]'} font-bold`}>{userProfile?.username || message.user.split(' ')[0]}</span>
                <p
                  className="text-white text-sm mt-1"
                  style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    maxWidth: '550px',
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(filteredMessage) }}
                ></p>
                <small className="text-gray-400 text-xs mt-1">{timeAgo}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex-grow overflow-y-auto p-6 bg-[#121212] rounded-lg shadow-md"
      ref={messageBoxRef}
      style={{ height: '100%' }}
    >
      <div className="flex flex-col space-y-4" style={{ paddingBottom: '16px' }}>
        {messages.map((message, index) => {
          const match = message.user.match(/\[(.*?)\]/);
          const messageWalletAddress = match ? match[1] : '';
          const userProfile = userProfiles[messageWalletAddress] || {};
          const isCurrentUser = currentAccount.toLowerCase() === messageWalletAddress.toLowerCase();

          return (
            <MessageItem
              key={index}
              message={message}
              userProfile={userProfile}
              isCurrentUser={isCurrentUser}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MessageList;