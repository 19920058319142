// MusicNFTList.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Web3Context } from '../context/Web3Context';
import { MusicPlayerContext } from '../context/MusicPlayerContext';
import { getContract } from '../contracts/MusicNFT';
import NFTItem from './NFTItem';
import TraitFilter from './TraitFilter';
import TransferModal from './TransferModal';
import Pagination from './Pagination';

const MusicNFTList = () => {
  const [nftList, setNftList] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [transferAddress, setTransferAddress] = useState('');
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [isTransferring, setIsTransferring] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 6;

  const { provider, signer, currentAccount, connectWallet } = useContext(Web3Context);
  const { playMusic, setAudioUrls, currentNFT, setAudioPlaylist  } = useContext(MusicPlayerContext);




  const handlePlayMusic = (nft) => {
    playMusic(`${process.env.REACT_APP_API_URL}/getAudio/${nft.metadata.audioToken}`, nft);
  };
  

  const fetchMetadata = useCallback(async (tokenURI) => {
    try {
      const response = await fetch(tokenURI);

      if (response.status === 404) {
        console.error(`Metadados não encontrados para a URI: ${tokenURI}`);
        return null;
      }

      if (!response.ok) throw new Error(`Erro ao buscar metadados: ${response.statusText}`);

      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error('Erro ao buscar os metadados:', error);
      return {
        name: 'Metadados indisponíveis',
        description: 'Não foi possível carregar os metadados. Por favor, tente novamente mais tarde.',
        image: 'fallback-image-url',
        attributes: []
      };
    }
  }, []);

  useEffect(() => {
    if (!currentAccount) connectWallet();
  }, [currentAccount, connectWallet]);

  const fetchNFTs = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      if (provider && signer && currentAccount) {
        const contract = getContract(signer);
        const tokenCounter = await contract.getTokenCounter();
        const totalTokens = Number(tokenCounter);

        let ownedNFTs = [];
        const promises = Array.from({ length: totalTokens }, async (_, tokenId) => {
          try {
            const ownerOfNFT = await contract.ownerOf(tokenId);
            if (ownerOfNFT.toLowerCase() === currentAccount.toLowerCase()) {
              const tokenURI = await contract.tokenURI(tokenId);
              const metadata = await fetchMetadata(tokenURI);
              if (metadata) ownedNFTs.push({ tokenId, metadata, owner: ownerOfNFT });
            }
          } catch (error) {
            console.error(`Token ${tokenId} não existe ou não pode ser acessado.`);
          }
        });

        await Promise.all(promises);
        setNftList(ownedNFTs);
      }
    } catch (error) {
      console.error('Erro ao buscar os NFTs:', error);
      setError('Houve um problema ao carregar seus NFTs. Por favor, tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  }, [provider, signer, currentAccount, fetchMetadata]);

  useEffect(() => {
    fetchNFTs();
  }, [fetchNFTs]);


  // Atualiza os URLs de músicas ao mudar o nftList
  useEffect(() => {
    const audioUrls = nftList
      .map(nft => `${process.env.REACT_APP_API_URL}/getAudio/${nft.metadata.audioToken}`)
      .filter(Boolean);
    
    console.log('Músicas atualizadas:', audioUrls);
    
    if (audioUrls.length > 0) {
      setAudioUrls(audioUrls);
      setAudioPlaylist(nftList); // Define a lista de NFTs
    }
  }, [nftList, setAudioUrls, setAudioPlaylist]);
  




  const handleTraitSelect = (trait) => {
    if (selectedTraits.includes(trait)) {
      setSelectedTraits(selectedTraits.filter(t => t !== trait));
    } else {
      setSelectedTraits([...selectedTraits, trait]);
    }
  };

  const handleClearTraits = () => {
    setSelectedTraits([]);
  };

  const handleTransferNFT = useCallback(async () => {
    if (!selectedNFT || !transferAddress) {
      alert('Por favor, selecione um NFT e insira um endereço válido para transferência.');
      return;
    }

    try {
      setIsTransferring(true);
      const contract = getContract(signer);
      const transaction = await contract.transferFrom(currentAccount, transferAddress, selectedNFT.tokenId);
      await transaction.wait();
      alert(`NFT transferido com sucesso para ${transferAddress}`);
      setTransferAddress('');
      setSelectedNFT(null);
      setShowModal(false);
      setIsTransferring(false);

      setNftList(nftList.filter(nft => nft.tokenId !== selectedNFT.tokenId));
    } catch (error) {
      console.error('Erro ao transferir o NFT:', error);
      alert('Erro ao transferir o NFT. Verifique o console para mais detalhes.');
      setIsTransferring(false);
    }
  }, [selectedNFT, transferAddress, signer, currentAccount, nftList]);

  const handleBurnNFT = useCallback(async (nft) => {
    try {
      if (!window.confirm("Você tem certeza que deseja queimar este NFT? Esta ação é irreversível.")) return;

      const contract = getContract(signer);
      const transaction = await contract.burn(nft.tokenId);
      await transaction.wait();

      alert("NFT queimado com sucesso!");

      const updatedNFTs = nftList.filter(item => item.tokenId !== nft.tokenId);
      setNftList(updatedNFTs);
    } catch (error) {
      console.error('Erro ao queimar NFT:', error);
      alert('Falha ao queimar o NFT. Verifique o console para mais detalhes.');
    }
  }, [signer, nftList]);

  const openModal = (nft) => {
    setSelectedNFT(nft);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedNFT(null);
    setTransferAddress('');
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="bg-[#121212] min-h-screen p-6 text-white">
      <h2 className="text-4xl font-bold mb-10 text-center text-[#1DB954]">Minhas Músicas NFT</h2>

      <div className="flex">
        <div className="w-1/4 mr-6">
          <TraitFilter
            nfts={nftList}
            selectedTraits={selectedTraits}
            onSelect={handleTraitSelect}
            onClear={handleClearTraits}
          />
        </div>

        <div className="w-3/4">
          {loading ? (
            <div className="text-center text-[#1DB954]">Carregando NFTs...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {nftList
                .filter(nft =>
                  selectedTraits.every(trait =>
                    nft.metadata.attributes.some(attr => `${attr.trait_type}:${attr.value}` === trait)
                  )
                )
                .slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE)
                .map((nft, index) => (
                <NFTItem
                  key={index}
                  nft={nft}
                  playMusic={() => handlePlayMusic(nft)} // Passa a função com o NFT
                  openModal={openModal}
                  burnNFT={handleBurnNFT}
                  currentAccount={currentAccount}
                  showActions={true}
                  isPlaying={currentNFT && String(currentNFT.tokenId) === String(nft.tokenId)} // Adicione esta linha
                />
                ))}
            </div>
          )}

          <Pagination
            pageCount={Math.ceil(nftList.length / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </div>
      </div>

      {showModal && (
        <TransferModal
          selectedNFT={selectedNFT}
          transferAddress={transferAddress}
          setTransferAddress={setTransferAddress}
          handleTransferNFT={handleTransferNFT}
          closeModal={closeModal}
          isTransferring={isTransferring}
        />
      )}
    </div>
  );
};

export default MusicNFTList;