import React, { useEffect, useState, useContext } from 'react';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/MusicNFT';
import { Link } from 'react-router-dom';
import { FaBroadcastTower, FaCalendarAlt, FaTags, FaTshirt, FaMoneyBillWave, FaMusic, FaUsers } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Roadmap from './Roadmap';

// Hero Section Component
const HeroSection = () => {
  const [text, setText] = useState('DOGMA Music NFTs');

  useEffect(() => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?/`~';
    let interval;

    const animateText = () => {
      let currentIndex = 0;
      interval = setInterval(() => {
        setText((prev) => {
          const newText = prev.split('').map((char, index) => {
            if (index === currentIndex) {
              return chars[Math.floor(Math.random() * chars.length)];
            } else if (index < currentIndex) {
              return 'DOGMA Music NFTs'[index];
            }
            return char;
          }).join('');
          return newText;
        });
        currentIndex++;
        if (currentIndex === text.length) {
          clearInterval(interval);
          setTimeout(animateText, 2000); // Restart animation after delay
        }
      }, 75); // Reduced interval time for smoother transition
    };

    animateText();
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className="relative bg-[#121212] p-20 rounded-3xl shadow-2xl flex flex-col items-center backdrop-blur-lg border border-[#1DB954]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="relative z-10 text-center">
        <h1 className="text-7xl font-extrabold text-[#1DB954] mb-4 drop-shadow-lg font-sans">
          {text}
        </h1>
        <p className="text-2xl text-[#FFFFFF] mb-8 text-center leading-relaxed font-sans">
          Explore a revolução da música digital. Crie, colecione e venda músicas exclusivas como NFTs.
        </p>
        <div className="flex justify-center mt-8">
          <motion.div whileHover={{ scale: 1.1 }}>
            <Link to="/discover" className="bg-[#1DB954] text-[#121212] py-4 px-8 rounded-full font-bold shadow-md hover:shadow-xl transition font-sans">
              Junte-se agora
            </Link>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

// About Us Component
const AboutUs = () => (
  <motion.div
    className="bg-[#181818] p-16 rounded-3xl shadow-2xl backdrop-blur-lg border border-[#1DB954] relative"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1.5, ease: "easeOut" }}
  >
    <div className="relative z-10">
      <h2 className="text-6xl font-extrabold text-[#1DB954] mb-8 text-center drop-shadow-lg font-sans">
        Quem Somos Nós
      </h2>
      <motion.div
        className="space-y-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <p className="text-[#FFFFFF] text-2xl leading-relaxed font-sans">
          Imagine um universo onde a música eletrônica <strong>transcende limites</strong>, onde os artistas são <strong>verdadeiramente livres</strong> e <strong>donos do seu próprio destino</strong>. Nós não apenas acreditamos nesse futuro – estamos <strong>construindo ele</strong>, aqui e agora. Nossa jornada começou nas pistas de dança, no pulsar de cada beat, e se transformou em uma missão: <strong>redefinir o que significa viver a música</strong>.
        </p>
        <p className="text-[#B3B3B3] text-xl leading-relaxed font-sans">
          Desde os primeiros dias em que eu assistia meus primos transformarem suas casas em raves, até a primeira vez que fui impactado por um festival, a música eletrônica sempre foi um chamado. Aos 17 anos, organizei meu próprio festival, um <strong>sonho que se tornou realidade</strong>, mas também um <strong>grande desafio</strong>. Com cada erro e acerto, cada som que ecoava pelas colinas, fui moldando uma visão – uma visão que agora se <strong>materializa nesta plataforma</strong>.
        </p>
        <p className="text-[#B3B3B3] text-xl leading-relaxed font-sans">
          Aqui, a música não é apenas ouvida, ela é <strong>vivida</strong>. Nossa plataforma é a resposta ao que muitos artistas sempre buscaram: <strong>liberdade</strong> e <strong>descentralização</strong>. Com a tecnologia Web3 e NFTs, estamos oferecendo a oportunidade de transformar cada batida, cada melodia, em um <strong>ativo digital único</strong>, <strong>eterno</strong> e <strong>inviolável</strong>. Usando o poder do IPFS, garantimos que a sua música não apenas percorra o mundo – ela <strong>atravessa o universo</strong>, como um <strong>eco que nunca se apaga</strong>.
        </p>
        <p className="text-[#B3B3B3] text-xl leading-relaxed font-sans">
          Mas por que parar aí? Nós não queremos ser apenas mais uma plataforma de música eletrônica. Queremos ser o <strong>movimento que desperta o artista em você</strong>, que lhe dá o poder de <strong>comandar seu próprio destino</strong>, de <strong>se conectar com fãs</strong> de maneira <strong>autêntica</strong> e <strong>direta</strong>. Nosso propósito é que, ao navegar por aqui, você sinta a mesma energia que senti na pista pela primeira vez, que você entenda que não está apenas consumindo música – você está <strong>fazendo parte de uma revolução</strong>.
        </p>
        <p className="text-[#B3B3B3] text-xl leading-relaxed font-sans">
          <strong>Prepare-se para viver a música</strong> de um jeito que você nunca viveu antes. Bem-vindo à <strong>nova era da música eletrônica</strong>.
        </p>
        <div className="flex justify-center mt-8">
          <motion.div whileHover={{ scale: 1.1 }}>
            <span className="text-4xl font-extrabold text-[#1DB954] drop-shadow-lg font-sans">
              FAÇA PARTE DESSA <strong>REVOLUÇÃO INTERPLANETÁRIA!</strong>
            </span>
          </motion.div>
        </div>
      </motion.div>
    </div>
  </motion.div>
);




// Community Benefits Component
const CommunityBenefits = () => (
  <motion.div className="bg-[#121212] p-16 rounded-3xl shadow-2xl border border-[#1DB954]">
    <div className="relative z-10">
      <h2 className="text-6xl font-extrabold text-[#1DB954] mb-8 text-center">
        Benefícios de Fazer Parte da Comunidade DOGMA
      </h2>
      <motion.div 
        className="space-y-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        {benefits.map((benefit, index) => (
          <motion.div 
            key={index} 
            className="p-8 bg-[#1E1E1E] rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:bg-[#1DB954]"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.3, duration: 0.8 }}
          >
            <benefit.icon className="text-6xl text-[#1DB954]" />
            <h3 className="text-4xl font-bold text-[#1DB954] mb-4">{benefit.title}</h3>
            <p className="text-[#FFFFFF] text-lg">{benefit.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  </motion.div>
);


const benefits = [
  {
    icon: FaBroadcastTower,
    title: 'DOGMAVerse',
    description: 'Mergulhe no DOGMAVerse, uma experiência digital imersiva que conecta artistas e fãs em um ambiente virtual interativo. Participe de eventos exclusivos, interaja em tempo real e explore um novo universo onde a música eletrônica e a tecnologia se encontram.'
  },
  {
    icon: FaCalendarAlt,
    title: 'Experiências em Eventos Reais',
    description: 'Acesse festivais e encontros organizados pela comunidade DOGMA com benefícios e experiências diferenciadas. Desfrute de um ambiente que celebra a cultura da música eletrônica.'
  },
  {
    icon: FaTags,
    title: 'Promoções e Descontos',
    description: 'Aproveite descontos exclusivos em produtos e ingressos para eventos, incluindo itens de edição limitada. Faça parte de algo único e obtenha vantagens que valorizam a sua presença.'
  },
  {
    icon: FaTshirt,
    title: 'Merchandising Exclusivo',
    description: 'Adquira camisetas, acessórios e outros itens exclusivos da nossa marca. Mostre o seu apoio à comunidade DOGMA e sinta o orgulho de carregar um símbolo da música eletrônica descentralizada.'
  },
  {
    icon: FaMoneyBillWave,
    title: 'Royalties em Eventos',
    description: 'Receba royalties ao colaborar em eventos e festivais, tornando-se parte ativa da criação e produção. Contribua e seja recompensado por fazer parte dos momentos que marcam a nossa história.'
  },
  {
    icon: FaMusic,
    title: 'Ganhos com Músicas',
    description: 'Participe dos royalties de músicas compartilhadas e produzidas na plataforma, garantindo sua parte no sucesso coletivo. Transforme sua paixão por música em uma oportunidade de crescimento financeiro.'
  },
  {
    icon: FaUsers,
    title: 'Comunidade Interativa',
    description: 'Conecte-se com outros membros da comunidade, compartilhe suas ideias e colabore com artistas e criadores de conteúdo. Juntos, moldamos o futuro da música eletrônica com um espírito coletivo e inovador.'
  }
];


// Partners Component
const Partners = ({ partners }) => (
  <motion.div
    className="bg-[#121212] p-12 rounded-3xl shadow-2xl backdrop-blur-md"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <h2 className="text-5xl font-extrabold text-[#1DB954] mb-12 text-center drop-shadow-lg font-sans">
      Nossos Parceiros
    </h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
      {partners.map((partner, index) => (
        <motion.a
          href={partner.link}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#1E1E1E] p-6 rounded-lg flex flex-col items-center transition-transform transform hover:scale-110 hover:bg-[#1DB954] backdrop-blur-md shadow-md font-sans"
          whileHover={{ rotate: 5 }}
        >
          <img src={partner.logo} alt={partner.name} className="w-24 h-24 object-contain mb-4" />
          <h4 className="text-xl font-semibold text-[#1DB954] text-center font-sans">{partner.name}</h4>
        </motion.a>
      ))}
    </div>
  </motion.div>
);

// Homepage Component
const Homepage = () => {
  const { provider } = useContext(Web3Context);
  const [totalSupply, setTotalSupply] = useState(0);
  const [artistCount, setArtistCount] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [latestMintedNFTs, setLatestMintedNFTs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomepageData = async () => {
      if (provider) {
        const contract = getContract(provider);
        try {
          const tokenCounter = await contract.getTokenCounter();
          setTotalSupply(Number(tokenCounter));
          const mockVolume = Math.random() * 1000;
          setTotalVolume(mockVolume.toFixed(2));
          const uniqueArtists = new Set();

          const latestNFTs = await Promise.all(
            Array.from({ length: Math.min(5, Number(tokenCounter)) }, (_, i) => i + Number(tokenCounter) - 5).map(async (tokenId) => {
              try {
                const owner = await contract.ownerOf(tokenId);
                const metadata = await contract.tokenURI(tokenId);
                uniqueArtists.add(owner);
                return { owner, tokenId, metadata };
              } catch (error) {
                console.error(`Erro ao buscar owner para token ${tokenId}:`, error);
                return null;
              }
            })
          );
          setLatestMintedNFTs(latestNFTs.filter((nft) => nft !== null));
          setArtistCount(uniqueArtists.size);
          setLoading(false);
        } catch (error) {
          console.error('Erro ao buscar dados da homepage:', error);
          setLoading(false);
        }
      }
    };

    fetchHomepageData();
  }, [provider]);

  const partners = [
    { name: 'Festival Trancedance', logo: 'https://dmar.io/images/festivaltrancedance.gif', link: 'https://partner1.com' },
    { name: 'dMario Network', logo: 'https://cdn.discordapp.com/attachments/1046563483652718624/1290263279989559306/dmario.jpg?ex=66fbd2d1&is=66fa8151&hm=c0ca724a970a1f6861e6af127196acd14c3ee0a2f2045f4150eba29f4d4e33d3&', link: 'https://partner3.com' },
    { name: 'AuraDynamics', logo: 'https://via.placeholder.com/150', link: 'https://partner4.com' },
    { name: 'Polygon Blockchain', logo: 'https://i.pinimg.com/564x/db/50/42/db50425f0adb5b76f2db0b2f0f9085eb.jpg', link: 'https://partner2.com' },
  ];

  return (
    <div className="relative bg-[#121212] text-[#e0e1dd] p-20 space-y-32 min-h-screen font-sans">
      
      <HeroSection />
      <AboutUs />
      <CommunityBenefits />
      <motion.div
        className="bg-[#181818] p-16 rounded-3xl shadow-2xl backdrop-blur-md"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Roadmap />
      </motion.div>
      <Partners partners={partners} />
    </div>
  );
};

export default Homepage;