import React, { useEffect, useState, useContext, memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getContract } from '../contracts/MusicNFT';
import { Web3Context } from '../context/Web3Context';
import { MusicPlayerContext } from '../context/MusicPlayerContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import toast, { Toaster } from 'react-hot-toast';

// Custom components for cleaner code
import ProfileHeader from './ProfileHeader';
import NFTList from './NFTList';
import AchievementsList from './AchievementsList';
import ProfileDetails from './ProfileDetails';

const UserProfile = () => {
  const { wallet } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingNFTs, setLoadingNFTs] = useState(false);
  const [error, setError] = useState(null); // Estado para erros
  const { signer, provider } = useContext(Web3Context);
  const { playMusic } = useContext(MusicPlayerContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const normalizedWallet = useMemo(() => wallet.toLowerCase(), [wallet]);

  const fetchUserProfile = useCallback(async () => {
    setLoading(true);
    setError(null); // Resetar o erro antes de tentar novamente
    try {
      const response = await axios.get(`${API_URL}/api/user/${normalizedWallet}`);
      
      if (!response.data || Object.keys(response.data).length === 0) {
        // Perfil não encontrado
        setError('PERFIL NÃO ENCONTRADO');
        setUserProfile(null);
      } else {
        setUserProfile(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar perfil do usuário:', error);
      
      if (error.response && error.response.status === 404) {
        setError('Perfil não encontrado. Verifique se o endereço está correto.');
      } else {
        setError('Não foi possível carregar o perfil. Tente novamente mais tarde.');
        toast.error('Erro ao carregar perfil do usuário. Verifique sua conexão.');
      }
      setUserProfile(null);
    }
    setLoading(false);
  }, [API_URL, normalizedWallet]);

  const fetchUserNFTs = useCallback(async () => {
    setLoadingNFTs(true);
    try {
      if (!signer && !provider) {
        console.warn('Provedor ou signer não disponível');
        return;
      }

      const contract = getContract(signer || provider);
      const tokenCounter = await contract.getTokenCounter();
      const totalTokens = Number(tokenCounter);
      const ownedNFTs = [];
      const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

      const nftPromises = Array.from({ length: totalTokens }).map(async (_, tokenId) => {
        try {
          const ownerOfNFT = await contract.ownerOf(tokenId);
          if (ownerOfNFT.toLowerCase() === normalizedWallet) {
            const tokenURI = await contract.tokenURI(tokenId);
            if (!tokenURI) return null;

            const metadata = await fetch(proxyUrl + tokenURI)
              .then((res) => {
                if (!res.ok) throw new Error(`Erro ${res.status}: ${res.statusText}`);
                return res.json();
              })
              .catch(error => {
                console.warn(`Erro ao buscar metadados de ${tokenURI}:`, error);
                return null;
              });

            if (metadata) ownedNFTs.push({ tokenId, metadata });
          }
        } catch (error) {
          if (error.message.includes("invalid token ID")) {
            console.warn(`Token ${tokenId} não existe ou foi queimado.`);
          } else {
            console.error(`Erro ao buscar metadados para o token ${tokenId}:`, error);
          }
        }
      });

      await Promise.all(nftPromises);
      setNfts(ownedNFTs);
    } catch (error) {
      console.error('Erro ao buscar NFTs do usuário:', error);
      toast.error('Erro ao carregar NFTs');
    } finally {
      setLoadingNFTs(false);
    }
  }, [signer, provider, normalizedWallet]);

  useEffect(() => {
    fetchUserProfile();
    fetchUserNFTs();
  }, [fetchUserProfile, fetchUserNFTs]);

  // Aplicando o background ao body após carregar o userProfile
  useEffect(() => {
    if (userProfile?.backgroundPicture) {
      document.body.style.backgroundImage = `url(${userProfile.backgroundPicture})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
    }

    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [userProfile]);

  if (loading) {
    return (
      <div className="container mx-auto p-8 text-white bg-[#121212] min-h-screen">
        <Skeleton height={400} className="mb-6" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-[#282828] p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4"><Skeleton width={150} /></h3>
            <Skeleton count={6} />
          </div>
          <div className="bg-[#282828] p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-bold mb-4"><Skeleton width={150} /></h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="bg-[#3E3E3E] p-4 rounded-lg shadow">
                  <Skeleton height={160} />
                  <Skeleton width="80%" />
                  <Skeleton width="60%" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (error) {
    return (
      <div className="container mx-auto p-8 text-white min-h-screen flex items-center justify-center">
        <div className="bg-[#121212]/60 backdrop-blur-lg rounded-lg shadow-2xl border border-[#1DB954]/20 p-8">
          <p className="text-white text-center text-2xl">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-8 text-white min-h-screen">
      <Toaster position="top-right" />
      
      {/* Profile Header Component */}
      <ProfileHeader userProfile={userProfile} />
      
      {/* Tabs Section */}
      <Tabs className="mt-8">
        <TabList className="flex justify-around bg-[#282828]/50 backdrop-blur-lg rounded-lg p-2 shadow-lg mb-6 border border-[#1DB954]/20">
          <Tab className="cursor-pointer text-gray-300 hover:text-white focus:outline-none px-6 py-2 rounded-lg transition-all duration-300 hover:bg-[#1DB954]/10 focus:bg-[#1DB954]/20 focus:text-white shadow-sm border border-transparent hover:border-[#1DB954]">
            Perfil
          </Tab>
          <Tab className="cursor-pointer text-gray-300 hover:text-white focus:outline-none px-6 py-2 rounded-lg transition-all duration-300 hover:bg-[#1DB954]/10 focus:bg-[#1DB954]/20 focus:text-white shadow-sm border border-transparent hover:border-[#1DB954]">
            NFTs
          </Tab>
          <Tab className="cursor-pointer text-gray-300 hover:text-white focus:outline-none px-6 py-2 rounded-lg transition-all duration-300 hover:bg-[#1DB954]/10 focus:bg-[#1DB954]/20 focus:text-white shadow-sm border border-transparent hover:border-[#1DB954]">
            Conquistas
          </Tab>
        </TabList>

        {/* Profile Tab Panel */}
        <TabPanel>
          <ProfileDetails userProfile={userProfile} />
        </TabPanel>

        {/* NFTs Tab Panel */}
        <TabPanel>
          <NFTList nfts={nfts} userProfile={userProfile} loading={loadingNFTs} playMusic={playMusic} />
        </TabPanel>

        {/* Conquistas Tab Panel */}
        <TabPanel>
          <AchievementsList userProfile={userProfile} achievements={userProfile?.achievements} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default memo(UserProfile);
