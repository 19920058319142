// Pagination.js
import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <ReactPaginate
      previousLabel={'Anterior'}
      nextLabel={'Próximo'}
      breakLabel={'...'}
      pageCount={pageCount}
      forcePage={currentPage} // Mantém a página selecionada atual
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={(data) => onPageChange(data.selected)}
      containerClassName={'flex justify-center mt-8 space-x-2 bg-[#121212] p-4 rounded-lg shadow-md'} // Container da paginação
      activeClassName={'bg-[#1DB954] text-black font-bold rounded px-4 py-2 transition duration-300 ease-in-out transform hover:scale-105'}
      pageClassName={'px-4 py-2 rounded hover:bg-gray-700 cursor-pointer text-white transition duration-300 ease-in-out transform hover:scale-105'}
      breakClassName={'px-4 py-2 text-gray-400'}
      previousClassName={'px-4 py-2 rounded bg-gray-600 hover:bg-gray-700 text-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-105'}
      nextClassName={'px-4 py-2 rounded bg-gray-600 hover:bg-gray-700 text-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-105'}
      disabledClassName={'opacity-50 cursor-not-allowed'}
      previousLinkClassName={'flex items-center'}
      nextLinkClassName={'flex items-center'}
      breakLinkClassName={'flex items-center'}
      pageLinkClassName={'flex items-center'}
    />
  );
};

export default Pagination;