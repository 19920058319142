import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaPaperPlane, FaSmile, FaImage } from 'react-icons/fa';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { toast } from 'react-toastify';

const ComposeEmail = ({ newEmail, handleNewEmailChange, handleSendEmail, setComposingEmail }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const emojiPickerRef = useRef(null);

  const addEmoji = (emoji) => {
    handleNewEmailChange({
      target: {
        name: 'body',
        value: newEmail.body + emoji.native,
      },
    });
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + uploadedImages.length > 5) { // Limitar a 5 imagens
      toast.error("Você pode anexar até 5 imagens.");
      return;
    }
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const base64Image = readerEvent.target.result;
        setUploadedImages((prevImages) => [...prevImages, base64Image]);
      };
      reader.readAsDataURL(file);
    });
  };

  const renderUploadedImages = () => {
    return uploadedImages.map((image, index) => (
      <img
        key={index}
        src={image}
        alt="Upload preview"
        className="w-16 h-16 object-cover rounded-lg m-2"
      />
    ));
  };

  const handleSendWithImages = async () => {
    if (!newEmail.receiver || !newEmail.subject) {
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    const emailData = {
      ...newEmail,
      attachments: uploadedImages,
    };

    console.log('Dados enviados:', emailData);

    await handleSendEmail(emailData);
    toast.success("E-mail enviado com sucesso!");
    
    // Limpar campos após o envio
    handleNewEmailChange({ target: { name: 'body', value: '' } });
    handleNewEmailChange({ target: { name: 'subject', value: '' } });
    handleNewEmailChange({ target: { name: 'receiver', value: '' } });
    setUploadedImages([]);
  };

  return (
    <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} className="p-4">
      <button
        onClick={() => setComposingEmail(false)}
        className="text-gray-300 hover:text-green-400 transition duration-300 mb-4 flex items-center"
      >
        <FaArrowLeft className="mr-2" /> Voltar ao Inbox
      </button>

      <h2 className="text-2xl font-bold mb-2">Compor Novo E-mail</h2>

      <div className="mb-4">
        <label className="block text-gray-400">Destinatário (Username ou Carteira)</label>
        <input
          type="text"
          name="receiver"
          value={newEmail.receiver}
          onChange={handleNewEmailChange}
          className="w-full p-2 rounded-lg bg-gray-700 text-white"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-400">Assunto</label>
        <input
          type="text"
          name="subject"
          value={newEmail.subject}
          onChange={handleNewEmailChange}
          className="w-full p-2 rounded-lg bg-gray-700 text-white"
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-400">Mensagem</label>
        <textarea
          name="body"
          value={newEmail.body}
          onChange={handleNewEmailChange}
          className="w-full p-2 rounded-lg bg-gray-700 text-white"
          rows="6"
        ></textarea>
      </div>

      <div className="flex items-center space-x-4 mb-4">
        <button
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="text-yellow-400 hover:text-yellow-500 transition duration-300"
          aria-label="Selecionar emoji"
        >
          <FaSmile size={24} />
        </button>

        <label className="cursor-pointer text-blue-400 hover:text-blue-500 transition duration-300">
          <FaImage size={24} />
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            className="hidden"
          />
        </label>
      </div>

      {showEmojiPicker && (
        <div ref={emojiPickerRef} className="absolute z-50 bottom-12">
          <Picker data={data} onEmojiSelect={addEmoji} theme="dark" />
        </div>
      )}

      {uploadedImages.length > 0 && (
        <div className="mb-4">
          <h4 className="text-gray-400 mb-2">Imagens anexadas:</h4>
          <div className="flex space-x-2">{renderUploadedImages()}</div>
        </div>
      )}

      <button
        onClick={handleSendWithImages}
        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300 flex items-center"
      >
        <FaPaperPlane className="mr-1" /> Enviar E-mail
      </button>
    </motion.div>
  );
};

export default ComposeEmail;
