import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MdFiberManualRecord } from 'react-icons/md';

const NavBar = ({ navLinks, toggleMenu, isMenuOpen }) => {
  const location = useLocation();

  return (
    <nav
      className={`fixed inset-0 bg-gray-900 bg-opacity-70 lg:static lg:bg-transparent lg:flex items-center justify-center transition-all duration-300 ${
        isMenuOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      } lg:opacity-100 lg:pointer-events-auto`}
      aria-label="Navegação principal"
    >
      <ul className="flex flex-col lg:flex-row lg:space-x-6 mt-8 lg:mt-0 space-y-4 lg:space-y-0 text-center lg:text-left">
        {navLinks.map((link) => (
          <li key={link.path}>
            <NavLink
              to={link.path}
              end
              className={({ isActive }) =>
                `text-gray-300 hover:text-green-400 transition duration-300 relative ${
                  isActive ? 'text-green-400' : ''
                }`
              }
              onClick={toggleMenu}
              style={
                link.label === 'BUY KEYPASS'
                  ? {
                      color: '#ff4d4d',
                      background: 'rgba(255, 77, 77, 0.2)',
                      borderRadius: '8px',
                      padding: '0.5rem 1rem',
                      fontWeight: 'bold',
                      border: '1px solid rgba(255, 77, 77, 0.5)',
                      boxShadow: '0 0 10px rgba(255, 77, 77, 0.5)',
                    }
                  : link.label === 'Mint NFT'
                  ? {
                      color: '#4dff88',
                      background: 'rgba(77, 255, 136, 0.2)',
                      borderRadius: '8px',
                      padding: '0.5rem 1rem',
                      fontWeight: 'bold',
                      border: '1px solid rgba(77, 255, 136, 0.5)',
                      boxShadow: '0 0 10px rgba(77, 255, 136, 0.5)',
                    }
                  : link.label === 'DOGMAVerse'
                  ? {
                      color: '#a74cff',
                      background: 'rgba(167, 76, 255, 0.2)',
                      borderRadius: '8px',
                      padding: '0.5rem 1rem',
                      fontWeight: 'bold',
                      border: '1px solid rgba(167, 76, 255, 0.5)',
                      boxShadow: '0 0 10px rgba(167, 76, 255, 0.5)',
                    }
                  : {}
              }
            >
              {link.label}

              {link.label === 'DOGMAVerse' && (
                <span className="ml-1 relative inline-flex">
                  {/* Envolva o ponto vermelho em um span e ajuste sua posição */}
                  <span
                    style={{
                      position: 'absolute',
                      top: '-27px', // Ajuste para mover para cima
                      left: '-12px', // Ajuste para mover para a esquerda
                    }}
                  >
                    <MdFiberManualRecord size={12} className="text-red-500" />
                  </span>
                  <span
                    style={{
                      position: 'absolute',
                      top: '-29px', // Ajuste para mover o texto para cima
                      left: '0px', // Deixe este como 0 para alinhar com o ponto
                      fontSize: '0.7rem',
                      color: 'white',
                      textShadow: '0px 0px 5px rgba(0, 0, 0, 0.8)',
                    }}
                  >
                    LIVE
                  </span>
                </span>
              )}

              {location.pathname === link.path && (
                <span className="absolute left-0 -bottom-1 w-full h-0.5 bg-green-400 transform scale-x-100 transition-transform duration-300"></span>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
