import React, { useState } from 'react';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import Pagination from '../Pagination';

const EmailList = ({ emails, handleSelectEmail }) => {
  // Estado para controlar a página atual e emails por página
  const [currentPage, setCurrentPage] = useState(0);
  const emailsPerPage = 5; // Quantidade de emails por página

  // Cálculo do início e fim dos emails para a página atual
  const startIdx = currentPage * emailsPerPage;
  const selectedEmails = emails.slice(startIdx, startIdx + emailsPerPage);

  // Total de páginas
  const pageCount = Math.ceil(emails.length / emailsPerPage);

  // Função para trocar de página
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div>
      <ul className="bg-[#121212] p-4 rounded-lg">
        {selectedEmails.map((email) => (
          <li
            key={email._id || email.id}
            onClick={() => handleSelectEmail(email)}
            className={`p-4 mb-4 bg-[#282828] hover:bg-[#333] rounded-lg cursor-pointer transition duration-300 flex items-center gap-4 ${
              email.isRead ? 'opacity-60' : 'opacity-100'
            }`}
          >
            <FaEnvelopeOpenText size={24} className="text-[#1DB954]" />
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-white mb-1">{email.subject}</h3>
              <p className="text-sm text-gray-400">
                {email.body.length > 50 ? `${email.body.slice(0, 50)}...` : email.body}
              </p>
            </div>
          </li>
        ))}
      </ul>

      {/* Componente de Paginação */}
      <Pagination 
        pageCount={pageCount} 
        currentPage={currentPage} 
        onPageChange={handlePageChange} 
      />
    </div>
  );
};

export default EmailList;
