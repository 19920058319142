import React, { useEffect } from 'react';
import { FaTwitter, FaDiscord, FaSoundcloud, FaInstagram, FaFacebook, FaCheckCircle, FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ProfileDetails = ({ userProfile }) => {

  const member = userProfile.group?.members?.find(
    (member) => member.wallet === userProfile.wallet
  );



  useEffect(() => {
    if (userProfile?.backgroundPicture) { // Verifica se userProfile está definido
      document.body.style.backgroundImage = `url(${userProfile.backgroundPicture})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
    }
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [userProfile?.backgroundPicture]);


  return (
    <motion.div
      className="grid grid-cols-1 lg:grid-cols-2 gap-6 p-8 bg-[#121212]/60 backdrop-blur-lg rounded-lg shadow-2xl border border-[#1DB954]/20"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      {/* Perfil Section */}
      <div className="bg-[#282828]/60 backdrop-blur-lg p-6 rounded-lg shadow-lg border border-[#1DB954]/30 transition duration-300 transform hover:scale-105">
        <h3 className="text-2xl font-bold mb-4 text-[#1DB954] border-b border-[#1DB954]/30 pb-2">Perfil</h3>
        <div className="space-y-4">
          <p className="text-lg font-semibold text-white">{userProfile.fullName}</p>
          <p className="text-gray-300">{userProfile.bio}</p>
          {userProfile.location && (
            <p className="flex items-center space-x-2 text-gray-400">
              <FaMapMarkerAlt />
              <span>{userProfile.location}</span>
            </p>
          )}
          <div className="flex flex-wrap items-center gap-3 mt-4">
          {userProfile.socialLinks.twitter && (
            <a
              href={userProfile.socialLinks.twitter}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 bg-[#1DA1F2] bg-opacity-80 text-white rounded-lg hover:shadow-md hover:bg-opacity-100 transition-all"
            >
              <FaTwitter /> Twitter
            </a>
          )}
          {userProfile.socialLinks.discord && (
            <a
              href={`https://discord.com/users/${userProfile.socialLinks.discord}`}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 bg-[#7289DA] bg-opacity-80 text-white rounded-lg hover:shadow-md hover:bg-opacity-100 transition-all"
            >
              <FaDiscord /> Discord
            </a>
          )}
          {userProfile.socialLinks.soundcloud && (
            <a
              href={userProfile.socialLinks.soundcloud}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 bg-[#FF5500] bg-opacity-80 text-white rounded-lg hover:shadow-md hover:bg-opacity-100 transition-all"
            >
              <FaSoundcloud /> Soundcloud
            </a>
          )}
          {userProfile.socialLinks.instagram && (
            <a
              href={userProfile.socialLinks.instagram}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 bg-[#E4405F] bg-opacity-80 text-white rounded-lg hover:shadow-md hover:bg-opacity-100 transition-all"
            >
              <FaInstagram /> Instagram
            </a>
          )}
          {userProfile.socialLinks.facebook && (
            <a
              href={userProfile.socialLinks.facebook}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-3 py-2 bg-[#1877F2] bg-opacity-80 text-white rounded-lg hover:shadow-md hover:bg-opacity-100 transition-all"
            >
              <FaFacebook /> Facebook
            </a>
          )}
           
            {/* Outros links sociais */}
          </div>
          {userProfile.isArtist && (
            <div className="flex items-center space-x-2 mt-6">
              <FaCheckCircle className="text-[#1DB954]" />
              <span className="text-sm bg-[#1DB954]/90 text-black px-3 py-1 rounded-full font-semibold">Artista Verificado</span>
            </div>
          )}
          {/* Gêneros Musicais como tags */}
          {userProfile.preferences.genres.length > 0 && (
            <div className="mt-6">
              <h4 className="text-lg font-bold text-[#1DB954] mb-2">Gêneros Musicais</h4>
              <div className="flex flex-wrap gap-2">
                {userProfile.preferences.genres.map((genre, index) => (
                  <span key={index} className="bg-[#1DB954]/80 text-black px-3 py-1 rounded-full text-sm font-semibold">
                    {genre}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Records Section */}
      <div className="bg-[#282828]/60 backdrop-blur-lg p-6 rounded-lg shadow-lg border border-[#1DB954]/30 transition duration-300 transform hover:scale-105">
        <h3 className="text-2xl font-bold mb-4 text-[#1DB954] border-b border-[#1DB954]/30 pb-2">Records</h3>
        {userProfile.group ? (
          <>
            <div className="flex items-center space-x-6">
              <img
                src={userProfile.group.image}
                alt="Imagem do Grupo"
                className="w-24 h-24 rounded-lg shadow-lg transition-transform hover:scale-110"
              />
              <div>
                <p className="text-xl font-bold text-white">{userProfile.group.name}</p>
                <p className="text-sm text-gray-300">
                  Membro desde {member?.joinedAt ? new Date(member.joinedAt).toLocaleDateString() : 'Data não disponível'}
                </p>
                <p className="text-sm text-gray-300 mt-2">
                  Criado por <span className="font-bold text-[#1DB954]">{userProfile.group.owner}</span> em {new Date(userProfile.group.createdAt).toLocaleDateString()}
                </p>
                <p className="text-sm text-gray-300 mt-2">
                  Cargo: <span className="font-bold text-white">{member?.role || 'Membro'}</span>
                </p>
              </div>
            </div>
            <div className="mt-4 text-gray-400">
              <h4 className="text-lg font-bold text-[#1DB954]">Descrição:</h4>
              <p className="mt-2">{userProfile.group.description || 'Nenhuma descrição definida.'}</p>
            </div>
            <div className="mt-4 text-gray-400">
              <h4 className="text-lg font-bold text-[#1DB954]">Visibilidade:</h4>
              <p className="mt-2">
                {userProfile.group.visibility === 'public' ? 'Público' : 'Privado'}
              </p>
            </div>
            {userProfile.group.tags.length > 0 && (
              <div className="mt-4">
                <h4 className="text-lg font-bold text-[#1DB954]">Tags do Grupo:</h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {userProfile.group.tags.map((tag, index) => (
                    <span key={index} className="bg-[#1DB954]/80 text-black px-3 py-1 rounded-full text-sm font-semibold">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="text-gray-400">Nenhuma crew registrada.</p>
        )}
        {userProfile.achievements.length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-bold text-[#1DB954] mb-2">Conquistas</h4>
            <ul className="list-disc pl-6 text-gray-300">
              {userProfile.achievements.map((achievement, index) => (
                <li key={index}>{achievement}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default ProfileDetails;
