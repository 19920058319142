import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Web3Context } from '../context/Web3Context';
import CrewForm from './crew-module/CrewForm';
import CrewList from './crew-module/CrewList';
import CrewHome from './crew-module/CrewHome'; // Importação do CrewHome

const Crew = () => {
  const { currentAccount, connectWallet } = useContext(Web3Context);
  const [crews, setCrews] = useState([]);
  const [isLoadingCrews, setIsLoadingCrews] = useState(true);
  const [expandedCrew, setExpandedCrew] = useState(null);
  const [activeTab, setActiveTab] = useState('home'); // Define 'home' como a tab inicial
  const API_URL = process.env.REACT_APP_API_URL;

  // Função para buscar os últimos grupos criados
  const fetchCrews = async () => {
    try {
      setIsLoadingCrews(true);
      const response = await axios.get(`${API_URL}/api/group`);
      setCrews(response.data);
    } catch (error) {
      console.error('Erro ao buscar os grupos:', error);
    } finally {
      setIsLoadingCrews(false);
    }
  };

  useEffect(() => {
    fetchCrews();
  }, []);

  // Função para alternar entre as tabs
  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  // Função para expandir/recolher membros do grupo
  const toggleCrewMembers = (crewId) => {
    setExpandedCrew((prev) => (prev === crewId ? null : crewId));
  };

  return (
    <div className="min-h-screen p-8 text-white bg-[#121212] flex">
      {/* Menu Lateral */}
      <div className="w-1/4 bg-[#181818] p-6 rounded-lg shadow-lg flex flex-col justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-6 text-[#1DB954]">Menu</h2>
          <ul className="space-y-4">
            {/* Abas de navegação semelhantes ao ProfileManager */}
            <li>
              <button
                className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 ${
                  activeTab === 'home' ? 'bg-[#1DB954] text-black' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
                onClick={() => setActiveTab('home')}
              >
                Home
              </button>
            </li>
            <li>
              <button
                className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 ${
                  activeTab === 'list' ? 'bg-[#1DB954] text-black' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
                onClick={() => setActiveTab('list')}
              >
                Listar Crews
              </button>
            </li>
            <li>
              <button
                className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 ${
                  activeTab === 'create' ? 'bg-[#1DB954] text-black' : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                }`}
                onClick={() => setActiveTab('create')}
              >
                Criar Crew
              </button>
            </li>
          </ul>
        </div>

        {/* Placeholder para algo futuro, como ajuda ou contato */}
        <button className="bg-gray-600 text-white px-4 py-3 rounded-md hover:bg-gray-500 transition-all">
          Fale Conosco
        </button>
      </div>

      {/* Área de Conteúdo */}
      <div className="w-3/4 ml-8 bg-[#181818] p-6 rounded-lg shadow-lg">
        {/* Exibe CrewHome, CrewList ou CrewForm com base na tab ativa */}
        {activeTab === 'home' ? (
          <CrewHome />
        ) : activeTab === 'list' ? (
          <CrewList
            crews={crews}
            isLoadingCrews={isLoadingCrews}
            expandedCrew={expandedCrew}
            toggleCrewMembers={toggleCrewMembers}
            fetchCrews={fetchCrews}
          />
        ) : (
          <CrewForm currentAccount={currentAccount} connectWallet={connectWallet} fetchCrews={fetchCrews} />
        )}
      </div>
    </div>
  );
};

export default Crew;
