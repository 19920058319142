// NFTItem.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useLikes from '../hooks/useLikes';
import '../NFTItem.css';

const NFTItem = ({
  nft,
  playMusic,
  openModal,
  burnNFT,
  currentAccount,
  showActions,
  isPlaying,
}) => {
  const { likes, liked, toggleLike } = useLikes(nft?.tokenId, currentAccount);
  const isOwner =
    currentAccount &&
    nft?.owner &&
    currentAccount.toLowerCase() === nft.owner.toLowerCase();
  const [isExpanded, setIsExpanded] = useState(false);

  if (!nft || !nft.owner) {
    return <div>Carregando NFT...</div>;
  }

  // Função para truncar o texto
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  // Manipuladores de eventos
  const handleToggleLike = (e) => {
    e.stopPropagation();
    toggleLike();
  };

  const handleToggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    openModal(nft);
  };

  const handleBurnNFT = (e) => {
    e.stopPropagation();
    burnNFT(nft);
  };

  return (
    <div
      className={`nft-card spotify-theme bg-[#121212] border p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col md:flex-row gap-4 relative overflow-hidden ${
        isPlaying ? 'border-[#1DB954]' : 'border-[#282828]'
      }`}
      onClick={playMusic}
    >
      <div
        className="nft-background-image absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-40"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${nft.metadata?.image || 'https://via.placeholder.com/150'})`,
        }}
      />
  
      <div className="nft-card-content relative z-10 flex-1 flex flex-col justify-between h-full">
        <div className="nft-header mb-2">
          <h4 className="text-[#1DB954] font-bold text-lg mb-1 shadow-md">
            {nft.metadata?.name || 'Sem Nome'}
          </h4>
        </div>
  
        <div className="nft-description mb-2 text-gray-400">
          <p className="text-sm mb-1">
            {isExpanded
              ? nft.metadata?.description || 'Sem descrição disponível'
              : truncateText(
                  nft.metadata?.description || 'Sem descrição disponível',
                  100
                )}
          </p>
          {nft.metadata?.description &&
            nft.metadata.description.length > 100 && (
              <button
                className="text-[#1DB954] text-sm underline"
                onClick={handleToggleExpand}
              >
                {isExpanded ? 'Ver menos' : 'Ver mais'}
              </button>
            )}
        </div>
  
        <div className="nft-info mb-2 text-xs">
          <p className="text-gray-500 mb-1">
            <strong>ID do Token:</strong> {nft.tokenId}
          </p>
          <p className="text-gray-500">
            <strong>Dono:</strong>
            {nft.owner ? (
              <Link
                to={`/profile/${nft.owner}`}
                className="text-[#1DB954] hover:underline ml-1"
              >
                {nft.owner.slice(0, 6)}...{nft.owner.slice(-4)}
              </Link>
            ) : (
              <span className="text-red-400">Desconhecido</span>
            )}
          </p>
        </div>
  
        {nft.metadata?.attributes && (
          <div className="nft-attributes flex flex-wrap gap-2 mt-2 text-xs">
            {nft.metadata.attributes.map((attr, index) => (
              <span
                key={index}
                className="bg-[#1DB954] text-black py-1 px-2 rounded"
              >
                {attr.trait_type}: {attr.value}
              </span>
            ))}
          </div>
        )}
  
        <div className="nft-actions flex items-center justify-between mt-3">
          <button
            className="text-[#1DB954] font-semibold hover:text-green-300 transition text-sm flex items-center gap-1"
            onClick={handleToggleLike}
            aria-label={liked ? 'Descurtir' : 'Curtir'}
          >
            <span
              className={`${
                liked ? 'text-red-500' : 'text-gray-500'
              } transition transform ${
                liked ? 'scale-110' : ''
              }`}
            >
              {liked ? '❤️' : '🤍'}
            </span>
            {likes}
          </button>
  
          {isOwner && showActions && (
            <div className="flex gap-2">
              <button
                onClick={handleOpenModal}
                className="bg-[#1ED760] text-black hover:bg-[#1DB954] transition font-bold py-1 px-3 rounded text-sm"
              >
                Transferir
              </button>
              <button
                onClick={handleBurnNFT}
                className="bg-red-600 text-white hover:bg-red-800 transition font-bold py-1 px-3 rounded text-sm"
              >
                Queimar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  
};

export default NFTItem;
