import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/MusicNFT';
import SearchBar from './SearchBar';
import TraitFilter from './TraitFilter';
import Pagination from './Pagination';
import NFTItem from './NFTItem';
import { MusicPlayerContext } from '../context/MusicPlayerContext';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

const Discover = () => {
  const { provider, signer, currentAccount, connectWallet } = useContext(Web3Context);
  const { playMusic, setAudioUrls, currentNFT, setAudioPlaylist } = useContext(MusicPlayerContext);
  const [nfts, setNfts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const ITEMS_PER_PAGE = 6;

  const navigate = useNavigate();
  const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

  const fetchMetadata = useCallback(async (tokenURI) => {
    try {
      const response = await fetch(proxyUrl + tokenURI);
      if (response.status === 404) {
        console.error(`Metadados não encontrados para a URI: ${tokenURI}`);
        return null;
      }
      if (!response.ok) throw new Error(`Erro ao buscar metadados: ${response.statusText}`);
      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error('Erro ao buscar os metadados:', error);
      return {
        name: 'Metadados indisponíveis',
        description: 'Não foi possível carregar os metadados. Por favor, tente novamente mais tarde.',
        image: 'fallback-image-url',
        attributes: []
      };
    }
  }, []);

  const fetchNFTs = useCallback(async () => {
    try {
      if (!provider || !signer) return;
      setLoading(true);
      setError(null);

      const contract = getContract(signer);
      const tokenCounter = await contract.getTokenCounter();
      const promises = Array.from({ length: Number(tokenCounter) }, async (_, tokenId) => {
        try {
          const owner = await contract.ownerOf(tokenId);
          const tokenURI = await contract.tokenURI(tokenId);
          const metadata = await fetchMetadata(tokenURI);
          return { tokenId, metadata, owner };
        } catch (error) {
          if (error.reason === "ERC721: invalid token ID") {
            console.warn(`Token ${tokenId} não existe ou foi queimado.`);
          } else {
            console.error(`Erro ao buscar metadados para o token ${tokenId}:`, error);
          }
          return null;
        }
      });

      const fetchedNFTs = (await Promise.all(promises)).filter(nft => nft !== null);
      setNfts(fetchedNFTs);
    } catch (error) {
      console.error('Erro ao buscar NFTs:', error);
      setError('Falha ao carregar NFTs. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  }, [provider, signer, fetchMetadata]);

  useEffect(() => {
    if (!currentAccount || !signer) {
      const storedAccount = localStorage.getItem('currentAccount');
      if (storedAccount) {
        connectWallet();
      } else {
        navigate('/login');
      }
    }
  }, [currentAccount, signer, connectWallet, navigate]);

  useEffect(() => {
    if (currentAccount && signer) {
      fetchNFTs();
    }
  }, [fetchNFTs, currentAccount, signer]);

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(0);
  };


  const handlePlayMusic = (nft) => {
    playMusic(`${process.env.REACT_APP_API_URL}/getAudio/${nft.metadata.audioToken}`, nft);
  };
  
  


  const handleTraitSelect = (trait) => {
    if (selectedTraits.includes(trait)) {
      setSelectedTraits(selectedTraits.filter(t => t !== trait));
    } else {
      setSelectedTraits([...selectedTraits, trait]);
    }
    setCurrentPage(0);
  };
  
  const handleTraitClear = () => setSelectedTraits([]);
  const handlePageChange = (page) => setCurrentPage(page);

  const filteredNFTs = nfts.filter(nft =>
    nft.metadata &&
    nft.metadata.name &&
    nft.metadata.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    selectedTraits.every(trait =>
      nft.metadata.attributes &&
      nft.metadata.attributes.some(attr => `${attr.trait_type}:${attr.value}` === trait)
    )
  );


  const [debouncedNFTs] = useDebounce(filteredNFTs, 300);


  // Ajuste no Discover.js
  useEffect(() => {
    const audioUrls = debouncedNFTs
      .map(nft => `${process.env.REACT_APP_API_URL}/getAudio/${nft.metadata.audioToken}`)
      .filter(Boolean);
  
    if (audioUrls.length > 0) {
      setAudioUrls(audioUrls);
      setAudioPlaylist(debouncedNFTs); // Define a lista de NFTs para o player
    }
  }, [debouncedNFTs, setAudioUrls, setAudioPlaylist]);
  
  

  const displayedNFTs = filteredNFTs.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

  return (
    <div className="bg-[#121212] min-h-screen p-8 text-white flex flex-col">
      <div className="flex flex-col lg:flex-row lg:space-x-8 mb-8">
        <div className="w-full lg:w-1/4 mb-8 lg:mb-0">
          <SearchBar searchTerm={searchTerm} onSearch={handleSearch} />
          <TraitFilter nfts={nfts} selectedTraits={selectedTraits} onSelect={handleTraitSelect} onClear={handleTraitClear} />
        </div>
        <div className="w-full lg:w-3/4">
          {loading ? (
            <div className="text-center text-[#1DB954]">Carregando NFTs...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : displayedNFTs.length === 0 ? (
            <div className="text-center text-gray-400">Nenhum NFT encontrado.</div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
              {displayedNFTs.map((nft, index) => (
                <NFTItem
                  key={index}
                  nft={nft}
                  playMusic={() => handlePlayMusic(nft)}
                  openModal={() => {}}
                  burnNFT={() => {}}
                  currentAccount={currentAccount}
                  showActions={false}
                  isPlaying={currentNFT && String(currentNFT.tokenId) === String(nft.tokenId)} // Adiciona esta linha
                />
              ))}
            </div>
          )}
          <div className="mt-6">
            <Pagination
              pageCount={Math.ceil(filteredNFTs.length / ITEMS_PER_PAGE)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
