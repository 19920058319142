// CrewEditModal.js
import React, { useState } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';

const CrewEditModal = ({ crew, onClose, fetchCrews }) => {
  const [formData, setFormData] = useState({
    name: crew.name,
    description: crew.description,
  });
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  // Lidar com a mudança dos campos de texto
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Lidar com o envio do formulário de edição
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Usuário não autenticado.');
        setLoading(false);
        return;
      }

      const response = await axios.put(`${API_URL}/api/group/${crew._id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        fetchCrews(); // Atualiza a lista de Crews
        onClose();
      }
    } catch (error) {
      console.error('Erro ao editar a Crew:', error.response || error.message);
    }
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#181818] p-8 rounded-lg shadow-lg w-full max-w-md relative">
        <button
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
          onClick={onClose}
        >
          <FaTimes />
        </button>
        <h3 className="text-2xl font-bold mb-6 text-[#1DB954]">Editar Crew</h3>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="form-group">
            <label className="form-label">Nome da Crew:</label>
            <input
              className="form-input"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label">Descrição:</label>
            <textarea
              className="form-input"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
            ></textarea>
          </div>
          <button
            type="submit"
            className={`submit-button mt-6 bg-[#1DB954] p-3 rounded-lg text-black flex items-center justify-center shadow-lg transform transition-transform duration-300 hover:scale-105 ${
              loading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={loading}
          >
            {loading ? 'Salvando...' : 'Salvar Alterações'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CrewEditModal;