import React from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaUsers, FaExpand, FaMusic } from 'react-icons/fa';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

// RoadmapStep Component: Simplificado para reduzir o impacto de animações e hover
const RoadmapStep = ({ step, isOdd }) => (
  <div className={`flex ${isOdd ? 'flex-row' : 'flex-row-reverse'} items-center w-full text-center mb-32`}>
    {/* Linha horizontal conectando ao ícone no centro */}
    <div className={`flex-1 h-1 ${isOdd ? 'bg-gradient-to-r from-transparent to-[#1DB954]' : 'bg-gradient-to-l from-transparent to-[#1DB954]'}`} />

    {/* Ícone interativo com Tooltip no centro */}
    <Tippy content={<span className="text-sm text-white">{step.description}</span>} placement="top" arrow={true}>
      <div className="bg-gradient-to-br from-[#1DB954] to-[#1ED760] p-6 rounded-full shadow-2xl transform hover:scale-110 transition duration-300 cursor-pointer">
        {step.icon}
      </div>
    </Tippy>

    <div className={`flex-1 h-1 ${isOdd ? 'bg-gradient-to-l from-[#1DB954] to-transparent' : 'bg-gradient-to-r from-[#1DB954] to-transparent'}`} />

    {/* Detalhes do Roadmap */}
    <motion.div
      className={`bg-gradient-to-br from-[#282828] to-[#121212] p-12 rounded-3xl shadow-2xl w-full lg:w-2/3 ${
        isOdd ? 'ml-12' : 'mr-12'
      }`}
      initial={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5, delay: step.index * 0.2 }}
    >
      <h3 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#1DB954] to-[#1ED760] mb-3">
        {step.quarter}
      </h3>
      <h4 className="text-3xl font-bold text-white mb-4">{step.title}</h4>
      <p className="text-gray-400">{step.description}</p>
    </motion.div>
  </div>
);

// Roadmap Component: Optimized for performance with simplified animations
const Roadmap = () => {
  const roadmapSteps = [
    {
      quarter: 'Q4 2024',
      title: 'Lançamento Beta',
      description: 'Lançamento da plataforma com funcionalidades de cunhagem e troca de NFTs.',
      icon: <FaRocket className="text-6xl text-white" />,
    },
    {
      quarter: 'Q1 2025',
      title: 'Integração com Artistas',
      description: 'Parcerias com artistas emergentes para oferecer coleções exclusivas de música NFT.',
      icon: <FaUsers className="text-6xl text-white" />,
    },
    {
      quarter: 'Q2 2025',
      title: 'Expansão do Marketplace',
      description: 'Lançamento do marketplace para compra e venda de NFTs, com recursos avançados de streaming.',
      icon: <FaExpand className="text-6xl text-white" />,
    },
    {
      quarter: 'Q3 2025',
      title: 'DOGMA Live',
      description: 'Lançamento de shows e performances ao vivo integrados diretamente com a plataforma.',
      icon: <FaMusic className="text-6xl text-white" />,
    },
  ];

  return (
    <div className="bg-gradient-to-b from-[#121212] via-[#181818] to-[#121212] p-20 rounded-3xl shadow-2xl border-4 border-[#1DB954]">
      <h2 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#1DB954] to-[#1ED760] mb-24 text-center">
        Nosso Roadmap Explosivo
      </h2>
      <div className="space-y-32">
        {roadmapSteps.map((step, index) => (
          <RoadmapStep key={index} step={{ ...step, index }} isOdd={index % 2 === 0} />
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
