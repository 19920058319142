import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { FaSpinner, FaPlus } from 'react-icons/fa';
import axios from 'axios';
import { Web3Context } from '../context/Web3Context';
import { useSearchParams } from 'react-router-dom';
import EmailList from './inbox-module/EmailList';
import EmailDetails from './inbox-module/EmailDetails';
import ComposeEmail from './inbox-module/ComposeEmail';
import { toast } from 'react-toastify';

const Inbox = () => {
  const { currentAccount } = useContext(Web3Context);
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [composingEmail, setComposingEmail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newEmail, setNewEmail] = useState({ receiver: '', subject: '', body: '' });
  const [searchParams] = useSearchParams();

  const fetchEmails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/emails/${currentAccount.toLowerCase()}`);
      setEmails(response.data);
    } catch (error) {
      console.error('Erro ao carregar e-mails:', error);
      toast.error('Erro ao carregar e-mails!');
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (currentAccount) {
      fetchEmails();
    }
  }, [currentAccount]);

  useEffect(() => {
    const emailId = searchParams.get('email');
    if (emailId && emails.length > 0) {
      const foundEmail = emails.find((email) => email._id === emailId);
      if (foundEmail) {
        setSelectedEmail(foundEmail);
      }
    }
  }, [searchParams, emails]);

  const handleSelectEmail = (email) => {
    setSelectedEmail(email);
  };

  const handleBackToInbox = () => setSelectedEmail(null);

  const handleNewEmailChange = (e) => setNewEmail({ ...newEmail, [e.target.name]: e.target.value });

  const handleSendEmail = async (emailData) => {
    if (!emailData.receiver || !emailData.subject || !emailData.body) {
      toast.warn('Todos os campos são obrigatórios!');
      return;
    }
  
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/emails`, {
        subject: emailData.subject,
        body: emailData.body,
        receiver: emailData.receiver,
        sender: currentAccount,
        attachments: emailData.attachments,
      });
      toast.success('E-mail enviado com sucesso!');
      setComposingEmail(false);
      await fetchEmails();
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);
      toast.error('Erro ao enviar e-mail.');
    }
  };
  

  const handleDeleteEmail = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/emails/${id}`);
      toast.success('E-mail excluído com sucesso!');
      await fetchEmails();
      setSelectedEmail(null);
    } catch (error) {
      console.error('Erro ao excluir e-mail:', error);
      toast.error('Erro ao excluir e-mail.');
    }
  };
  

  const handleMarkAsRead = async (id) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/emails/${id}/read`);
      fetchEmails();
    } catch (error) {
      console.error('Erro ao marcar e-mail como lido:', error);
      toast.error('Erro ao marcar e-mail como lido.');
    }
  };
  

  return (
    <div className="container mx-auto mt-8 p-6 bg-[#121212] text-white rounded-lg shadow-lg">
      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-gray-400 text-4xl" />
        </div>
      ) : composingEmail ? (
        <ComposeEmail
          newEmail={newEmail}
          handleNewEmailChange={handleNewEmailChange}
          handleSendEmail={handleSendEmail}
          setComposingEmail={setComposingEmail}
        />
      ) : selectedEmail ? (
        <EmailDetails
          selectedEmail={selectedEmail}
          handleBackToInbox={handleBackToInbox}
          handleDeleteEmail={handleDeleteEmail}
          handleMarkAsRead={handleMarkAsRead}
          handleSendEmail={handleSendEmail}
        />
      ) : (
        <motion.div initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-4xl font-bold text-[#1DB954]">Inbox</h2>
            <button
              onClick={() => setComposingEmail(true)}
              className="bg-[#1DB954] text-black px-4 py-2 rounded-full hover:bg-[#1ED760] transition duration-300 flex items-center space-x-2 shadow-md"
            >
              <FaPlus /> <span>Novo E-mail</span>
            </button>
          </div>
          {emails.length > 0 ? (
            <EmailList emails={emails} handleSelectEmail={handleSelectEmail} />
          ) : (
            <p className="text-gray-400">Você não tem e-mails no seu inbox.</p>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default Inbox;