// forbiddenWords.js

// Exporta um Set com palavras que são ofensas sérias
export const forbiddenWordsSet = new Set([
    "racista", "homofóbico", "misógino", "fascista", "nazista", "pedófilo", "estuprador",
    "genocida", "torturador", "terrorista", "safado", "canalha", "vagabundo", "desgraçado",
    "marginal", "ladrão", "assassino", "criminoso", "puta", "piranha", "corno", "filhadaputa",
    "puto", "cuzão", "arrombado", "escroto", "safada", "prostituta", "caralho", "porra",
    "merda", "fdp", "fudido", "babaca", "imbecil", "idiota", "otário", "cretino", "vagabunda",
    "nojento", "nojo", "doente", "burro", "covarde", "palerma", "bosta", "puta-que-pariu",
    "fdp", "miserável", "inútil", "asqueroso", "repugnante", "ridículo", "escória", 
    "maconheiro", "traficante", "estuprador", "abusador", "abusiva", "explorador", 
    "pedófila", "opressor", "mentiroso", "demente", "racismo", "homofobia", "abusiva", 
    "perverso", "misógina", "discriminador", "peste", "cretina", "incestuoso", 
    "maníaco", "aliciador", "explorador", "infame", "indecente", "boçal", "pervertido",
    "sanguessuga", "manipulador", "odiador", "exploradora", "infeliz", "odioso", 
    "imoral", "mentiroso", "revoltado", "satânico", "excomungado", "execrável",
    "maníaco", "lunático", "retardado", "marginalizado", "subversivo", "imundo", 
    "abominável", "perigoso", "mentecapto", "cínico", "fascínora", "ultrajante", 
    "nefasta", "vilão", "traidor", "ameaçador", "abusiva", "covarde", "explorador",
    "cabeça-oca", "incapaz", "incompetente", "mal-intencionado", "ultrajante", 
    "crápula", "perversidade", "desonesto", "delinquente", "desumano", "sádico", 
    "nefasta", "negligente", "profano", "impuro", "detestável", "enganador",
    "perigosa", "perturbador", "pária", "infame", "repulsivo", "degradante",
    "tóxico", "doentio", "intolerante", "fanático", "bastardo", "maldita", 
    "inimiga", "repulsiva", "intimidador", "blasfemo", "calhorda"
    // Continue adicionando conforme necessário
  ]);
  