import React, { useEffect, useRef, useState, useCallback } from 'react';
import shaka from 'shaka-player';
import { FaRedo, FaExclamationTriangle } from 'react-icons/fa'; // Importando ícones

const ShakaPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);
  const [isStreamOnline, setIsStreamOnline] = useState(true);
  const observerRef = useRef(null);
  const playerRef = useRef(null);

  const initializePlayer = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;

    if (playerRef.current) {
      playerRef.current.destroy();
      playerRef.current = null;
    }

    const player = new shaka.Player(video);
    playerRef.current = player;

    player.configure({
      streaming: {
        rebufferingGoal: 1,
        bufferingGoal: 2,
        bufferBehind: 1,
        lowLatencyMode: true
      }
    });

    player.addEventListener('error', onErrorEvent);

    function onErrorEvent(event) {
      console.error('Erro no Shaka Player:', event.detail);
      setError('Erro ao carregar o vídeo.');
      setIsStreamOnline(false);
    }

    function onStreamStarted() {
      setIsStreamOnline(true);
      setError(null);
    }

    player.load(src).then(onStreamStarted).catch((error) => {
      console.error('Erro ao carregar o vídeo:', error);
      setError('Erro ao carregar o vídeo.');
      setIsStreamOnline(false);
    });

    return () => {
      player.destroy();
    };
  }, [src]);

  useEffect(() => {
    if (isPlayerVisible) {
      initializePlayer();
    }
  }, [isPlayerVisible, initializePlayer]);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPlayerVisible(true);
        } else {
          setIsPlayerVisible(false);
        }
      });
    };

    observerRef.current = new IntersectionObserver(observerCallback, { threshold: 0.5 });

    if (videoRef.current) {
      observerRef.current.observe(videoRef.current);
    }

    return () => {
      if (observerRef.current && videoRef.current) {
        observerRef.current.unobserve(videoRef.current);
      }

      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
    };
  }, []);

  const handleReloadPlayer = () => {
    setError(null);
    setIsStreamOnline(true);
    initializePlayer();
  };

  return (
    <div
      className="video-container relative"
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#1b1b1b',
        borderRadius: '12px',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {error && !isStreamOnline && (
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-80 backdrop-blur-md">
          <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-gray-900 bg-opacity-60">
            <FaExclamationTriangle className="text-yellow-500 text-4xl mb-4" />
            <p className="text-white text-lg mb-4 text-center animate-pulse">Sua transmissão não está disponível no momento.</p>
            <button
              onClick={handleReloadPlayer}
              className="bg-gradient-to-r from-indigo-600 to-blue-600 text-white py-2 px-6 rounded-full hover:bg-gradient-to-r hover:from-indigo-700 hover:to-blue-700 transition duration-300 transform hover:scale-105 shadow-lg flex items-center"
              style={{
                padding: '10px 20px',
                fontWeight: 'bold',
                border: '2px solid rgba(255, 255, 255, 0.2)',
                background: 'rgba(255, 255, 255, 0.1)',
              }}
            >
              <FaRedo className="mr-2 text-xl" />
              Reiniciar Transmissão
            </button>
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        className={`w-full h-full object-cover ${!isStreamOnline ? 'hidden' : ''}`}
        controls
        autoPlay={isPlayerVisible}
        style={{
          display: isStreamOnline ? 'block' : 'none',
          borderRadius: '8px',
        }}
      />
    </div>
  );
};

export default ShakaPlayer;
