import React, { useState, useRef, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const NFTList = ({ nfts, loading, playMusic, userProfile }) => {
  const [displayedNfts, setDisplayedNfts] = useState([]);
  const [expandedNft, setExpandedNft] = useState(null); // Estado para controlar o item expandido
  const observerRef = useRef();

  useEffect(() => {
    if (!loading && nfts.length > 0) {
      setDisplayedNfts(nfts.slice(0, 6)); // Inicializa com os primeiros 6
    }
  }, [loading, nfts]);

  useEffect(() => {
    if (userProfile.backgroundPicture) {
      document.body.style.backgroundImage = `url(${userProfile.backgroundPicture})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
    }
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [userProfile.backgroundPicture]);

  const lastNftRef = (node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setDisplayedNfts((prev) => {
          const moreNfts = nfts.slice(prev.length, prev.length + 6);
          return [...prev, ...moreNfts];
        });
      }
    });

    if (node) observerRef.current.observe(node);
  };

  const toggleExpand = (tokenId) => {
    setExpandedNft(expandedNft === tokenId ? null : tokenId);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {loading ? (
        Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="bg-gray-800 p-3 rounded-lg shadow-md">
            <Skeleton height={160} />
            <Skeleton width="80%" />
            <Skeleton width="60%" />
          </div>
        ))
      ) : displayedNfts.length > 0 ? (
        displayedNfts.map((nft, index) => {
          const isExpanded = expandedNft === nft.tokenId;

          return (
        <motion.div 
  key={index} 
  className={`compact-nft-item bg-[#121212] border border-[#282828] p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105 relative overflow-hidden ${
    isExpanded ? 'h-auto' : 'h-40'
  }`}
  onClick={() => playMusic(`${process.env.REACT_APP_API_URL}/getAudio/${nft.metadata.audioToken}`)}
  whileHover={{ scale: 1.03 }}
  whileTap={{ scale: 0.97 }}
  ref={index === displayedNfts.length - 1 ? lastNftRef : null}
>
  <div
    className="nft-background-image absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-20"
    style={{ backgroundImage: `url(${nft.metadata?.image || 'https://via.placeholder.com/150'})` }}
  />
  <div className="nft-card-content relative z-10 flex flex-col justify-between h-full">
    <div>
      <h4 className="text-[#1DB954] font-semibold text-sm mb-1 truncate">{nft.metadata?.name || 'No Name'}</h4>
      <p className={`text-gray-400 mb-1 text-xs ${isExpanded ? '' : 'truncate'}`}>
        {nft.metadata?.description || 'No Description Available'}
      </p>
      <p className="text-gray-500 text-xs">
        <strong>Token ID:</strong> {nft.tokenId}
      </p>
    </div>
    <div className="flex items-center justify-between mt-2">
      <Link
        to={`/profile/${nft.owner}`}
        className="text-[#1DB954] hover:underline text-xs font-semibold"
        onClick={(e) => e.stopPropagation()}
      >
        Owner
      </Link>
      <button
        className="text-[#1DB954] text-xs font-semibold"
        onClick={(e) => {
          e.stopPropagation();
          toggleExpand(nft.tokenId);
        }}
      >
        {isExpanded ? 'Ver Menos' : 'Ver Mais'}
      </button>
    </div>
  </div>
        </motion.div>

          );
        })
      ) : (
        <p className="text-gray-400">Nenhum NFT encontrado.</p>
      )}
    </div>
  );
};

export default NFTList;
