import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTwitter, FaSoundcloud, FaDiscord } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Logo from './Logo';

const Footer = () => {
  return (
    <footer className="bg-[#121212] text-white py-4 mt-4 relative z-50">
      <div className="container mx-auto px-6 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Seção Sobre */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
        <div className="flex items-center space-x-2"> {/* Flex container */}
          <h3 className="text-xl font-bold text-[#1DB954]">Sobre a</h3>
          <div className="w-20 h-auto mt-1"> {/* Ajuste o tamanho do contêiner conforme necessário */}
            <Logo color="#1db954" width="100%" height="100%" />
          </div>
        </div>
            <p className="text-gray-400 mb-2 text-sm">
              DOGMA é uma plataforma revolucionária que conecta artistas e fãs por meio de NFTs de música, permitindo a criação, coleção e comércio de músicas digitais exclusivas.
            </p>
            <Link to="/about" className="text-[#1ED760] hover:text-[#1DB954] transition text-sm">Saiba Mais</Link>
          </motion.div>

          {/* Links Rápidos */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <h3 className="text-xl font-bold mb-2 text-[#1DB954]">Links Rápidos</h3>
            <ul className="space-y-1">
              <li><Link to="/" className="text-gray-400 hover:text-white transition text-sm">Início</Link></li>
              <li><Link to="/discover" className="text-gray-400 hover:text-white transition text-sm">Discover</Link></li>
              <li><Link to="/mint" className="text-gray-400 hover:text-white transition text-sm">Mint NFT</Link></li>
              <li><Link to="/events" className="text-gray-400 hover:text-white transition text-sm">Eventos</Link></li>
              <li><Link to="/profilemanager" className="text-gray-400 hover:text-white transition text-sm">Meu Perfil</Link></li>
            </ul>
          </motion.div>

          {/* Redes Sociais */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <h3 className="text-xl font-bold mb-2 text-[#1DB954]">Conecte-se Conosco</h3>
            <div className="flex space-x-4">
              <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">
                <FaTwitter size={20} />
              </a>
              <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">
                <FaInstagram size={20} />
              </a>
              <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">
                <FaFacebookF size={20} />
              </a>
              <a href="https://soundcloud.com/yourprofile" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">
                <FaSoundcloud size={20} />
              </a>
              <a href="https://discord.com/invite/yourdiscord" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition">
                <FaDiscord size={20} />
              </a>
            </div>
          </motion.div>
        </div>

        {/* Copyright */}
        <motion.div
          className="mt-6 border-t border-[#282828] pt-2 text-center text-gray-500 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <p>© {new Date().getFullYear()} DOGMA Music NFTs. Todos os direitos reservados.</p>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
