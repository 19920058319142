import React, { useState, useEffect } from 'react';
import '../../LoadingScreen.css';

const LoadingScreen = ({ onTimeout, hasAccess }) => {
  const [hasTimedOut, setHasTimedOut] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [asciiIndex, setAsciiIndex] = useState(0);
  const phrases = [
    'CARREGANDO CHAVE DE ACESSO',
    'VERIFICANDO BLOCKCHAIN',
    'VERIFICANDO CONEXÃO INTERPLANETÁRIA',
    'VERIFICAÇÃO CONCEDIDA...'
  ];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const asciiArt1 = `████████▄   ▄██████▄     ▄██████▄    ▄▄▄▄███▄▄▄▄      ▄████████ 
███   ▀███ ███    ███   ███    ███ ▄██▀▀▀███▀▀▀██▄   ███    ███ 
███    ███ ███    ███   ███    █▀  ███   ███   ███   ███    ███ 
███    ███ ███    ███  ▄███        ███   ███   ███   ███    ███ 
███    ███ ███    ███ ▀▀███ ████▄  ███   ███   ███ ▀███████████ 
███    ███ ███    ███   ███    ███ ███   ███   ███   ███    ███ 
███   ▄███ ███    ███   ███    ███ ███   ███   ███   ███    ███ 
████████▀   ▀██████▀    ████████▀   ▀█   ███   █▀    ███    █▀  `;

  const asciiArt2 = ` ▄█    █▄     ▄████████    ▄████████    ▄████████    ▄████████ 
███    ███   ███    ███   ███    ███   ███    ███   ███    ███ 
███    ███   ███    █▀    ███    ███   ███    █▀    ███    █▀  
███    ███  ▄███▄▄▄      ▄███▄▄▄▄██▀   ███         ▄███▄▄▄     
███    ███ ▀▀███▀▀▀     ▀▀███▀▀▀▀▀   ▀███████████ ▀▀███▀▀▀     
███    ███   ███    █▄  ▀███████████          ███   ███    █▄  
███    ███   ███    ███   ███    ███    ▄█    ███   ███    ███ 
 ▀██████▀    ██████████   ███    ███  ▄████████▀    ██████████ 
                          ███    ███                           `;

  useEffect(() => {
    const asciiInterval = setInterval(() => {
      setAsciiIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
    }, 2000);

    return () => clearInterval(asciiInterval);
  }, []);

  useEffect(() => {
    let currentCharIndex = 0;
    let phraseInterval;

    const revealPhrase = () => {
      if (currentCharIndex < phrases[currentPhraseIndex].length) {
        setDisplayedMessage((prev) => prev + phrases[currentPhraseIndex][currentCharIndex]);
        currentCharIndex++;
      } else {
        clearInterval(phraseInterval);
        setTimeout(() => {
          setDisplayedMessage('');
          setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        }, 1000);
      }
    };

    phraseInterval = setInterval(revealPhrase, 100);

    return () => clearInterval(phraseInterval);
  }, [currentPhraseIndex]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHasTimedOut(true);
      if (onTimeout) onTimeout();
    }, 7500);

    const progressInterval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 1 : prev));
    }, 75);

    return () => {
      clearTimeout(timeout);
      clearInterval(progressInterval);
    };
  }, [onTimeout]);

  if (hasTimedOut && !hasAccess) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-121212 relative overflow-hidden" role="alert" aria-busy="true" aria-live="assertive">
        <div className="ascii-container error">
          <pre className="ascii-text glow-error">
            {asciiIndex === 0 ? asciiArt1 : asciiArt2}
          </pre>
        </div>
        <div className="text-container mt-8">
          <pre className="whitespace-pre-wrap leading-tight text-center">
            <code className="error-message">
              ERRO!! LICENÇA NÃO ENCONTRADA
            </code>
          </pre>
        </div>
        <div className="progress-bar-container mt-4 error">
          <div className="progress-bar full-error" style={{ width: `100%` }}></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-121212 relative overflow-hidden" role="alert" aria-busy="true" aria-live="assertive">
      <div className="ascii-container">
        <pre className="ascii-text glow">
          {asciiIndex === 0 ? asciiArt1 : asciiArt2}
        </pre>
      </div>
      <div className="text-container mt-8">
        <pre className="whitespace-pre-wrap leading-tight text-center">
          <code className="typing-animation">
            {displayedMessage}
            <span className="animated-ellipsis">
              <span>.</span><span>.</span><span>.</span>
            </span>
          </code>
        </pre>
      </div>
      <div className="progress-bar-container mt-4">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
