// UserMenu.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWallet } from 'react-icons/fa';
import { toast } from 'react-toastify';

const UserMenu = ({ currentAccount, userProfile, connectWallet }) => {
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnectWallet = async () => {
    try {
      setIsConnecting(true);
      await connectWallet();
    } catch (error) {
      console.error('Erro ao conectar a carteira:', error);
      toast.error('Erro ao conectar a carteira.');
    } finally {
      setIsConnecting(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      {currentAccount ? (
        <Link
          to="/profilemanager"
          className="flex items-center bg-[#282828] text-green-400 py-2 px-4 rounded-full hover:bg-[#3e3e3e] transition duration-300 shadow-md transform hover:scale-105 border border-green-400"
          aria-label="Gerenciar Perfil"
        >
          {userProfile?.profilePicture ? (
            <img src={userProfile.profilePicture} alt="Profile" className="w-8 h-8 rounded-full border border-green-400 mr-3" />
          ) : (
            <FaWallet className="text-green-400 mr-3" />
          )}
          <span className="text-md font-semibold">
            {userProfile?.username || `${currentAccount.slice(0, 6)}...${currentAccount.slice(-4)}`}
          </span>
        </Link>
      ) : (
        <button
          onClick={handleConnectWallet}
          disabled={isConnecting}
          className={`bg-gradient-to-r from-green-500 to-green-600 text-white font-bold py-2 px-6 rounded-full hover:from-green-400 hover:to-green-500 transition duration-300 shadow-lg transform hover:scale-105 ${
            isConnecting ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          aria-label="Conectar MetaMask"
        >
          {isConnecting ? 'Conectando...' : 'Conectar MetaMask'}
        </button>
      )}
    </div>
  );
};

export default UserMenu;
