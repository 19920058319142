// SearchBar.js
import React from 'react';

const SearchBar = ({ searchTerm, onSearch }) => {
  return (
    <div className="mb-6">
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => onSearch(e.target.value)}
        placeholder="Buscar faixas"
        className="w-full p-4 rounded-lg bg-[#121212] text-white border border-[#282828] focus:outline-none focus:ring-2 focus:ring-[#1DB954] focus:border-transparent placeholder-gray-400"
      />
    </div>
  );
};

export default SearchBar;