// UserProfilePopup.js
import React from 'react';
import { FaTimes, FaEnvelope, FaMapMarkerAlt, FaTwitter, FaDiscord, FaUser } from 'react-icons/fa';

const UserProfilePopup = ({ selectedUserProfile, closeUserProfilePopup }) => {
  if (!selectedUserProfile) return null;

  // Função para exibir a tag do cargo (role)
  const getRoleTag = (role) => {
    const roleStyles = {
      ADMIN: 'bg-red-600 text-white',
      MODERATOR: 'bg-yellow-500 text-black',
      USER: 'bg-green-500 text-white',
    };
    return <span className={`text-xs px-2 py-1 rounded-full ${roleStyles[role] || roleStyles.USER}`}>{role}</span>;
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50 transition-opacity duration-300 ease-in-out"
      onClick={closeUserProfilePopup} // Permite fechar o popup ao clicar fora
    >
      <div
        className="bg-gray-800 text-white p-6 rounded-lg w-96 shadow-2xl relative transform transition-transform duration-300 ease-in-out"
        onClick={(e) => e.stopPropagation()} // Evita o fechamento ao clicar dentro do popup
      >
        <button
          onClick={closeUserProfilePopup}
          className="absolute top-2 right-2 text-gray-400 hover:text-white transition duration-200"
          aria-label="Fechar"
        >
          <FaTimes size={20} />
        </button>
        <div className="text-center">
          <div className="relative w-24 h-24 mx-auto mb-4">
            <img
              src={selectedUserProfile.profilePicture || 'https://via.placeholder.com/150'}
              alt="Foto de perfil"
              className="w-24 h-24 rounded-full object-cover shadow-lg border-2 border-gray-700"
            />
            <div className="absolute -bottom-2 right-0">{getRoleTag(selectedUserProfile.role)}</div>
          </div>
          <h3 className="text-2xl font-bold mb-1">{selectedUserProfile.username}</h3>
          {selectedUserProfile.bio && (
            <p className="text-sm text-gray-300 mb-4">{selectedUserProfile.bio}</p>
          )}

          <div className="space-y-2">
            {selectedUserProfile.email && (
              <div className="flex items-center justify-center text-gray-400">
                <FaEnvelope className="mr-2" />
                <span>{selectedUserProfile.email}</span>
              </div>
            )}
            {selectedUserProfile.location && (
              <div className="flex items-center justify-center text-gray-400">
                <FaMapMarkerAlt className="mr-2" />
                <span>{selectedUserProfile.location}</span>
              </div>
            )}
          </div>

          {/* Adicionando links de redes sociais com ícones */}
          <div className="mt-4 flex justify-center space-x-4 text-gray-400">
            {selectedUserProfile.socialLinks?.twitter && (
              <a
                href={selectedUserProfile.socialLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 transition duration-200"
                aria-label="Twitter"
              >
                <FaTwitter size={20} />
              </a>
            )}
            {selectedUserProfile.discord && (
              <a
                href={selectedUserProfile.discord}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-purple-400 transition duration-200"
                aria-label="Discord"
              >
                <FaDiscord size={20} />
              </a>
            )}
            {/* Adicione mais redes sociais conforme necessário */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePopup;
