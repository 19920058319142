import React, { useContext, useRef, useEffect, useState, useCallback } from 'react';
import { MusicPlayerContext } from '../context/MusicPlayerContext';
import { Web3Context } from '../context/Web3Context';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaChevronDown, FaChevronUp, FaStepForward, FaStepBackward } from 'react-icons/fa';
import '../MusicPlayer.css';

const MusicPlayer = () => {
  const { audioUrl, playNext, playPrevious } = useContext(MusicPlayerContext); // Adicionado playPrevious para retroceder
  const { currentAccount } = useContext(Web3Context);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(() => parseFloat(localStorage.getItem('volume')) || 0.5);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const audioRef = useRef(null);


  const { provider, signer } = useContext(Web3Context);
  const { setAudioUrls } = useContext(MusicPlayerContext);
  const [nfts, setNfts] = useState([]);



  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      isPlaying ? audioRef.current.pause() : audioRef.current.play();
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
    localStorage.setItem('volume', newVolume);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current && !isSeeking && duration && !isNaN(duration)) {
      const current = audioRef.current.currentTime;
      setCurrentTime(current);
      setProgress((current / duration) * 100);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      const audioDuration = audioRef.current.duration;
      if (!isNaN(audioDuration) && audioDuration !== Infinity) {
        setDuration(audioDuration);
      } else {
        console.warn('Invalid duration:', audioDuration);
      }
    }
  };

  const handleSeekStart = () => {
    setIsSeeking(true);
  };

  const handleProgressChange = (e) => {
    const newProgress = parseFloat(e.target.value);
    if (duration && !isNaN(duration) && duration !== Infinity) {
      const newTime = (newProgress / 100) * duration;
      if (audioRef.current) {
        audioRef.current.currentTime = newTime;
      }
      setCurrentTime(newTime);
      setProgress(newProgress);
    } else {
      console.warn('Duration is not available yet.');
    }
  };

  const handleSeekEnd = () => {
    setIsSeeking(false);
  };

  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds === Infinity) return '0:00';
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    const audioElement = audioRef.current;
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    if (audioElement) {
      audioElement.addEventListener('play', handlePlay);
      audioElement.addEventListener('pause', handlePause);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('play', handlePlay);
        audioElement.removeEventListener('pause', handlePause);
      }
    };
  }, []);

  useEffect(() => {
    if (audioUrl && audioRef.current) {
      const playAudio = async () => {
        try {
          await audioRef.current.play();
          setIsPlaying(true);
        } catch (error) {
          console.error('Error playing audio:', error);
        }
      };
      playAudio();
    }
  }, [audioUrl]);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };
  

  return (
    currentAccount && audioUrl && (
      <div className={`music-player ${isMinimized ? 'minimized' : 'expanded'}`}>
        <div className="controls">
          <button onClick={toggleMinimize} className="text-lg focus:outline-none" aria-label={isMinimized ? 'Maximizar Player' : 'Minimizar Player'}>
            {isMinimized ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        </div>

        {!isMinimized && (
          <div className="player-content">
            <div className="playback-controls">
              <button onClick={playPrevious} className="text-xl focus:outline-none" aria-label="Retroceder">
                <FaStepBackward />
              </button>
              <button onClick={togglePlayPause} className="text-xl focus:outline-none" aria-label="Play/Pause">
                {isPlaying ? <FaPause /> : <FaPlay />}
              </button>
              <button onClick={playNext} className="text-xl focus:outline-none" aria-label="Avançar">
                <FaStepForward />
              </button>
            </div>
            <div className="progress-bar-mediaplayer">
              <input
                type="range"
                value={progress}
                disabled={!duration || duration === Infinity}
                onMouseDown={handleSeekStart}
                onTouchStart={handleSeekStart}
                onInput={handleProgressChange}
                onMouseUp={handleSeekEnd}
                onTouchEnd={handleSeekEnd}
                className="progress-bar"
                aria-label="Seek"
                min="0"
                max="100"
                step="0.1"
              />
              <span className="time">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
            <div className="volume-controls">
              <button onClick={() => setVolume(volume === 0 ? 0.5 : 0)} className="focus:outline-none">
                {volume === 0 ? <FaVolumeMute /> : <FaVolumeUp />}
              </button>
              <input type="range" value={volume} min="0" max="1" step="0.01" onChange={handleVolumeChange} className="volume-control" aria-label="Volume" />
            </div>
          </div>
        )}

        <audio
          ref={audioRef}
          src={audioUrl}
          onCanPlay={handleLoadedMetadata}
          onTimeUpdate={handleTimeUpdate}
          onEnded={playNext} // Avança para a próxima música quando termina
        />
      </div>
    )
  );
};

export default MusicPlayer;
