// context/MusicPlayerContext.js
import React, { createContext, useState } from 'react';

export const MusicPlayerContext = createContext();

export const MusicPlayerProvider = ({ children }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioUrls, setAudioUrls] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [currentNFT, setCurrentNFT] = useState(null); // Novo estado para o NFT atual
  const [audioPlaylist, setAudioPlaylist] = useState([]);

  const playMusic = (url, nft) => {
    if (url !== audioUrl) {
      setAudioUrl(url);
      setCurrentAudioIndex(audioUrls.indexOf(url));
      setCurrentNFT(nft); // Define o NFT atual
    }
  };

  const playNext = () => {
    if (audioUrls.length > 0) {
      const nextIndex = (currentAudioIndex + 1) % audioUrls.length;
      setCurrentAudioIndex(nextIndex);
      setAudioUrl(audioUrls[nextIndex]);
      setCurrentNFT(audioPlaylist[nextIndex]); // Atualiza o currentNFT

    }
  };

  const playPrevious = () => {
    if (audioUrls.length > 0) {
      const prevIndex = (currentAudioIndex - 1 + audioUrls.length) % audioUrls.length;
      setCurrentAudioIndex(prevIndex);
      setAudioUrl(audioUrls[prevIndex]);
      setCurrentNFT(audioPlaylist[prevIndex]); // Atualiza o currentNFT
    }
  };

  return (
    <MusicPlayerContext.Provider value={{ audioUrl, audioUrls, setAudioUrls, playMusic, playNext, playPrevious, currentNFT, audioPlaylist, setAudioPlaylist }}>
      {children}
    </MusicPlayerContext.Provider>
  );
};
