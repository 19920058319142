import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaEdit, FaTrash, FaCrown, FaUserShield, FaUser, FaPlus } from 'react-icons/fa';
import { motion } from 'framer-motion';
import axios from 'axios';

const roleIcons = {
  admin: <FaCrown className="text-yellow-400" />,
  moderator: <FaUserShield className="text-blue-400" />,
  member: <FaUser className="text-gray-400" />
};

const CrewItem = ({ crew, expandedCrew, toggleCrewMembers, handleEditCrew, handleDeleteCrew, fetchCrews }) => {
  const [newMemberWallet, setNewMemberWallet] = useState(''); // Estado para a nova carteira
  const [isAddingMember, setIsAddingMember] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddMember = async () => {
    if (!newMemberWallet) return; // Verifique se há uma carteira válida

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Usuário não autenticado.');
        return;
      }

      // Verifica se o membro já faz parte do grupo
      if (crew.members.some(member => member.wallet.toLowerCase() === newMemberWallet.toLowerCase())) {
        setErrorMessage('Este usuário já faz parte do grupo.');
        return;
      }

      // Busca as informações do perfil do usuário
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${newMemberWallet}`);
      if (userResponse.status === 404) {
        setErrorMessage('Usuário não encontrado.');
        return;
      }

      // Chamada para adicionar o membro ao grupo no backend
      await axios.post(`${process.env.REACT_APP_API_URL}/api/group/add-member`, {
        groupId: crew._id,
        wallet: newMemberWallet,
        role: 'member' // Papel padrão do novo membro
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Atualiza a lista de crews após adicionar o membro
      setNewMemberWallet('');
      setIsAddingMember(false);
      setErrorMessage('');
      fetchCrews(); // Atualiza a lista de membros
    } catch (error) {
      console.error('Erro ao adicionar membro:', error);
      setErrorMessage('Erro ao adicionar membro. Tente novamente.');
    }
  };

  return (
    <motion.div
      className="crew-item bg-[#282828] p-4 rounded-lg shadow-inner"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img
            src={crew.image || '/default_crew.png'}
            alt={crew.name}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div>
            <h4 className="text-xl font-bold text-white">{crew.name}</h4>
            <p className="text-gray-400">{crew.description}</p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            className="text-[#1DB954] hover:text-white transition-all"
            onClick={() => toggleCrewMembers(crew._id)}
          >
            {expandedCrew === crew._id ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <button
            className="text-yellow-500 hover:text-white transition-all"
            onClick={() => handleEditCrew(crew)}
          >
            <FaEdit />
          </button>
          <button
            className="text-red-500 hover:text-white transition-all"
            onClick={() => handleDeleteCrew(crew)}
          >
            <FaTrash />
          </button>
        </div>
      </div>

      {expandedCrew === crew._id && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          transition={{ duration: 0.5 }}
          className="mt-4"
        >
          <h5 className="text-lg text-[#1DB954] font-semibold">Membros:</h5>
          <ul className="ml-4 text-gray-400">
            {crew.members.length > 0 ? (
              crew.members.map((member, index) => (
                <li key={index} className="flex items-center gap-2 my-2">
                  <img
                    src={member.profilePicture || '/default_profile.png'}
                    alt={member.username || member.wallet}
                    className="w-8 h-8 rounded-full object-cover"
                  />
                  <span>{member.username || member.wallet}</span> {/* Exibe username, ou wallet como fallback */}
                  <span className="ml-2 text-sm">{roleIcons[member.role]}</span> {/* Exibe o ícone do papel */}
                  <span className="text-sm text-gray-500">({member.role})</span> {/* Exibe o nome do papel */}
                </li>
              ))
            ) : (
              <li>Nenhum membro ainda</li>
            )}
          </ul>

          {/* Adicionar novo membro */}
          {isAddingMember ? (
            <div className="mt-4">
              <input
                type="text"
                value={newMemberWallet}
                onChange={(e) => setNewMemberWallet(e.target.value)}
                placeholder="Endereço da carteira"
                className="text-black p-2 rounded-md"
              />
              <button
                className="ml-2 bg-[#1DB954] p-2 rounded-md text-white"
                onClick={handleAddMember}
              >
                Adicionar Membro
              </button>
              <button
                className="ml-2 bg-gray-500 p-2 rounded-md text-white"
                onClick={() => setIsAddingMember(false)}
              >
                Cancelar
              </button>
              {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
            </div>
          ) : (
            <button
              className="mt-4 bg-[#1DB954] p-2 rounded-md text-white flex items-center"
              onClick={() => setIsAddingMember(true)}
            >
              <FaPlus className="mr-2" /> Adicionar Membro
            </button>
          )}
        </motion.div>
      )}
    </motion.div>
  );
};

export default CrewItem;
