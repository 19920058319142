import React, { useContext, useState, useEffect, useReducer, useRef, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Web3Context } from '../context/Web3Context';
import { FaBars, FaTimes, FaEnvelope } from 'react-icons/fa';
import axios from 'axios';
import io from 'socket.io-client';
import NavBar from './NavBar';
import UserMenu from './UserMenu';
import Logo from './Logo';

import InboxTooltip from './InboxTooltip';
import { toast } from 'react-toastify';
import '../HeaderFlex.css';

// Definir o reducer para gerenciar o estado
const initialState = { messages: [], unreadCount: 0 };

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MESSAGES':
      const unreadEmails = action.payload.filter(email => !email.isRead).length;
      return { ...state, messages: action.payload, unreadCount: unreadEmails };
    case 'ADD_MESSAGE':
      const updatedMessages = [action.payload, ...state.messages];
      const newUnreadCount = updatedMessages.filter(email => !email.isRead).length;
      return { ...state, messages: updatedMessages, unreadCount: newUnreadCount };
    default:
      return state;
  }
}

const Header = () => {
  const { currentAccount, connectWallet, userProfile } = useContext(Web3Context);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const inboxRef = useRef(null);
  const socketRef = useRef(null); 

  // Usar useReducer para gerenciar o estado de mensagens e contador de não lidas
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const fetchMessages = async () => {
    if (!currentAccount) return;
  
    const cachedMessages = sessionStorage.getItem(`messages_${currentAccount}`);
    if (cachedMessages) {
      dispatch({ type: 'SET_MESSAGES', payload: JSON.parse(cachedMessages) });
      return;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/emails/${currentAccount}`);
      const messages = response.data;
      sessionStorage.setItem(`messages_${currentAccount}`, JSON.stringify(messages));
      dispatch({ type: 'SET_MESSAGES', payload: messages });
    } catch (error) {
      console.error('Erro ao carregar mensagens:', error);
    }
  };
  

  const toggleInbox = async () => {
    if (!currentAccount) {
      toast.error('Você precisa estar conectado para acessar a caixa de entrada.');
      return;
    }

    setIsInboxOpen(!isInboxOpen);

    if (!isInboxOpen) {
      await fetchMessages();
    }
  };

  useEffect(() => {
    if (currentAccount) {
      fetchMessages();
  
      if (!socketRef.current) {
        socketRef.current = io(process.env.REACT_APP_API_URL);
  
        socketRef.current.on('connect', () => {
          console.log('Conectado ao servidor Socket.IO');
          // Registra o usuário após a conexão
          socketRef.current.emit('register', currentAccount);
        });
  
        socketRef.current.on('email', (data) => {
          console.log('Nova mensagem de e-mail recebida:', data);
          dispatch({ type: 'ADD_MESSAGE', payload: data }); // Atualiza o estado com a nova mensagem
        });
  
        socketRef.current.on('disconnect', () => {
          console.log('Desconectado do servidor Socket.IO');
        });
      }
    } else {
      dispatch({ type: 'SET_MESSAGES', payload: [] });
    }
  
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [currentAccount]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inboxRef.current && !inboxRef.current.contains(event.target)) {
        setIsInboxOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectMessage = (messageId) => {
    setIsInboxOpen(false);
    if (messageId) {
      navigate(`/inbox?email=${messageId}`);
    } else {
      navigate('/inbox');
    }
  };

  const navLinks = useMemo(() => ([
    { path: '/', label: 'Home' },
    { path: '/mint', label: 'Mint NFT' },
    { path: '/nfts', label: 'My NFTs' },
    { path: '/discover', label: 'Discover' },
    { path: '/chat', label: 'DOGMAVerse' },
    { path: '/events', label: 'Event History' },
    { path: '/crew', label: 'Crews' },
    { path: '/keypass', label: 'BUY KEYPASS' },
  ]), []);


  return (
    <header
      className="fixed w-full top-0 z-50 mb-4"
      style={{
        background: 'rgba(18, 18, 18, 0.9)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid #282828',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
      }}
    >
      <div className="container mx-auto flex justify-between items-center p-4">
      <Link to="/" className="flex items-center space-x-4 text-white">
        <div className="w-36 h-auto"> {/* Define o contêiner com o tamanho desejado */}
          <Logo color="#1db954" width="100%" height="100%" /> {/* O Logo agora se ajusta ao contêiner */}
        </div>
      </Link>




        <div className="lg:hidden flex items-center">
          <button
            onClick={toggleMenu}
            aria-label="Alternar menu"
            className="text-gray-300 hover:text-[#1DB954] transition duration-300 focus:outline-none"
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        <NavBar navLinks={navLinks} toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />

        <div className="flex items-center space-x-2">
          <UserMenu currentAccount={currentAccount} userProfile={userProfile} connectWallet={connectWallet} />

          <div className="relative">
            <button
              onClick={toggleInbox}
              className="relative text-gray-300 hover:text-[#1DB954] transition duration-300 focus:outline-none p-2 rounded-full hover:bg-[#282828]"
              aria-label="Abrir caixa de entrada"
            >
              <FaEnvelope size={24} />
              {state.unreadCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center">
                  {state.unreadCount}
                </span>
              )}
            </button>

            {isInboxOpen && (
              <InboxTooltip
                messages={state.messages}
                handleSelectMessage={handleSelectMessage}
                inboxRef={inboxRef}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
