import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaPaperPlane, FaSmile } from 'react-icons/fa';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import debounce from 'lodash.debounce';

const MessageInput = React.memo(({ inputMessage, setInputMessage, sendMessage, handleTyping }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [error, setError] = useState(null);
  const emojiPickerRef = useRef(null);
  const inputRef = useRef(null);
  const MAX_MESSAGE_LENGTH = 500;

  // Função para adicionar o emoji ao campo de entrada
  const addEmoji = (emoji) => {
    setInputMessage((prevMessage) => prevMessage + emoji.native);
    inputRef.current.focus();
  };

  // Fechar o seletor de emojis ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target) &&
        !event.target.closest('.emoji-button')
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Função debounced para o handleTyping
  const debouncedHandleTyping = useCallback(
    debounce(() => {
      handleTyping();
    }, 3000),
    [handleTyping]
  );

  // Atualiza o valor do input com o estado atual apenas quando o estado de inputMessage muda
  useEffect(() => {
    if (inputRef.current && inputRef.current.value !== inputMessage) {
      inputRef.current.value = inputMessage;
    }
  }, [inputMessage]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_MESSAGE_LENGTH) {
      if (inputMessage !== value) {
        setInputMessage(value);
        debouncedHandleTyping();
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      try {
        sendMessage();
      } catch {
        setError('Não foi possível enviar a mensagem.');
      }
    }
  };

  const handleSendMessage = () => {
    try {
      sendMessage();
    } catch {
      setError('Não foi possível enviar a mensagem.');
    }
  };

  return (
    <div className="relative flex items-center mt-4">
      {/* Campo de entrada de texto */}
      <label htmlFor="message-input" className="sr-only">
        Digite sua mensagem
      </label>
      <div 
        className="flex-grow flex items-center p-2 rounded-full bg-[#1DB954] bg-opacity-10"
        style={{
          backdropFilter: 'blur(8px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
        }}
      >
        <input
          id="message-input"
          type="text"
          ref={inputRef}
          onInput={handleInputChange}
          onKeyDown={handleKeyPress}
          className="flex-grow p-2 rounded-full bg-transparent text-white focus:outline-none"
          placeholder="Digite sua mensagem..."
          aria-label="Campo de entrada de mensagem"
        />

        {/* Botão para abrir o seletor de emoji */}
        <button
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          className="emoji-button text-2xl text-[#1DB954] hover:text-[#1ED760] transition duration-300 mx-2"
          aria-label="Selecionar emoji"
        >
          <FaSmile />
        </button>
      </div>

      {/* Renderiza o seletor de emojis */}
      {showEmojiPicker && (
        <div
          ref={emojiPickerRef}
          className="absolute bottom-12 right-0 z-50"
          style={{ maxHeight: '50vh', overflowY: 'auto' }}
        >
          <Picker data={data} onEmojiSelect={addEmoji} theme="dark" />
        </div>
      )}

      {/* Botão para enviar a mensagem */}
      <button
        onClick={handleSendMessage}
        className="bg-[#1DB954] px-4 py-2 ml-2 rounded-full text-white hover:bg-[#1ED760] transition duration-300 flex items-center"
        aria-label="Enviar mensagem"
        style={{
          backdropFilter: 'blur(4px)',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
        }}
      >
        <FaPaperPlane className="ml-1" />
      </button>

      {/* Exibe mensagem de erro, se houver */}
      {error && (
        <div className="text-red-500 text-sm mt-2">
          {error}
        </div>
      )}
    </div>
  );
});

export default MessageInput;
