import React, { useEffect, useState, useContext } from 'react';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/MusicNFT';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import Pagination from './Pagination';

const EventHistory = () => {
  const { provider } = useContext(Web3Context);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const eventsPerPage = 10;

  useEffect(() => {
    const fetchEvents = async () => {
      if (provider) {
        const contract = getContract(provider);
        try {
          setLoading(true);
          const eventFilter = contract.filters.Transfer();
          const _events = await contract.queryFilter(eventFilter);

          const formattedEvents = _events.map((event) => ({
            from: event.args.from,
            to: event.args.to,
            tokenId: Number(event.args.tokenId),
            timestamp: format(new Date(event.blockNumber * 1000), 'dd/MM/yyyy HH:mm'),
            txHash: event.transactionHash,
          }));

          setEvents(formattedEvents);
          setLoading(false);
        } catch (error) {
          console.error('Erro ao obter eventos:', error);
          setError('Não foi possível carregar os eventos. Por favor, tente novamente mais tarde.');
          setLoading(false);
        }
      }
    };

    fetchEvents();
  }, [provider]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const pageCount = Math.ceil(events.length / eventsPerPage);
  const currentEvents = events.slice(
    currentPage * eventsPerPage,
    (currentPage + 1) * eventsPerPage
  );

  return (
    <motion.div
      className="max-w-7xl mx-auto p-8 mt-8 rounded-xl bg-[#1C1C1C] shadow-lg border border-[#282828] backdrop-blur-lg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      {/* Título e descrição */}
      <div className="text-center mb-6">
        <h2 className="text-3xl font-semibold text-[#1DB954]">
          Histórico de Eventos
        </h2>
        <p className="text-md text-gray-400 mt-2">
          Acompanhe todas as transferências de NFTs na plataforma. Transparência total e rastreabilidade no ecossistema.
        </p>
      </div>

      {loading ? (
        <div className="text-center text-gray-400 py-8">Carregando...</div>
      ) : error ? (
        <div className="text-center text-red-500 py-8">{error}</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-transparent border-collapse border border-gray-700">
              <thead>
                <tr className="bg-[#1DB954] bg-opacity-20 text-white">
                  <th className="py-3 px-4 border-b border-gray-700 text-left font-medium">De</th>
                  <th className="py-3 px-4 border-b border-gray-700 text-left font-medium">Para</th>
                  <th className="py-3 px-4 border-b border-gray-700 text-left font-medium">Token ID</th>
                  <th className="py-3 px-4 border-b border-gray-700 text-left font-medium">Data</th>
                  <th className="py-3 px-4 border-b border-gray-700 text-left font-medium">Ver Mais</th>
                </tr>
              </thead>
              <tbody>
                {currentEvents.length > 0 ? (
                  currentEvents.map((event, index) => (
                    <tr
                      key={index}
                      className="hover:bg-[#1DB954] hover:bg-opacity-30 transition duration-300 ease-in-out"
                    >
                      <td className="py-3 px-4 border-b border-gray-700">{event.from.slice(0, 6)}...{event.from.slice(-4)}</td>
                      <td className="py-3 px-4 border-b border-gray-700">{event.to.slice(0, 6)}...{event.to.slice(-4)}</td>
                      <td className="py-3 px-4 border-b border-gray-700">{event.tokenId}</td>
                      <td className="py-3 px-4 border-b border-gray-700">{event.timestamp}</td>
                      <td className="py-3 px-4 border-b border-gray-700 text-green-400">
                        <a
                          href={`https://etherscan.io/tx/${event.txHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline"
                        >
                          Etherscan
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center py-4 text-gray-500">
                      Nenhum evento encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </motion.div>
  );
};

export default EventHistory;
