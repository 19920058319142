import React, { useEffect } from 'react';

const AchievementsList = ({ achievements, userProfile }) => {

  // Aplica o backgroundPicture ao body quando achievements list é renderizado
  useEffect(() => {
    if (userProfile?.backgroundPicture) { // Verifica se userProfile está definido
      document.body.style.backgroundImage = `url(${userProfile.backgroundPicture})`;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundPosition = 'center';
      document.body.style.backgroundRepeat = 'no-repeat';
    }
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, [userProfile?.backgroundPicture]);
  
  return (
    <div className="bg-gray-800/80 p-6 rounded-lg shadow-md backdrop-blur-lg border border-[#1DB954]/30">
      <h3 className="text-2xl font-bold mb-4 text-[#1DB954]">Conquistas</h3>
      {achievements?.length > 0 ? (
        <ul className="list-disc pl-5 text-gray-300">
          {achievements.map((achievement, index) => (
            <li key={index} className="text-sm mb-2">
              <strong>{achievement.title}</strong>: {achievement.description}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-400">Nenhuma conquista ainda.</p>
      )}
    </div>
  );
};

// Exemplo de uso com conquistas fictícias:
const ExampleUsage = () => {
  const sampleAchievements = [
    { title: 'Primeira Venda', description: 'Realizou a primeira venda de NFT com sucesso.' },
    { title: 'Membro Verificado', description: 'Obteve o status de artista verificado na plataforma.' },
    { title: 'Participante de Evento', description: 'Participou de um evento exclusivo.' },
    { title: '10+ Likes', description: 'Recebeu mais de 10 likes em um NFT.' },
  ];

  const userProfile = { backgroundPicture: 'https://example.com/path/to/background-image.jpg' };

  return <AchievementsList achievements={sampleAchievements} userProfile={userProfile} />;
};

export default AchievementsList;
