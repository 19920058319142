// Web3Context.js
import React, { createContext, useState, useEffect } from 'react';
import { ethers, BrowserProvider } from 'ethers';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// Remover a importação do jwtDecode, já que não podemos usar com cookies httpOnly
// import { jwtDecode } from 'jwt-decode';

export const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL || 'https://api.dmar.io';
  const navigate = useNavigate();

  // Remover a função isTokenExpired
  // const isTokenExpired = (token) => { ... };

  // Função para lidar com a desconexão
  const handleDisconnect = () => {
    setCurrentAccount(null);
    setUserProfile(null);
    setProvider(null);
    setSigner(null);

    // Remove o currentAccount do localStorage
    localStorage.removeItem('currentAccount');
    localStorage.setItem('isLoggedIn', 'false');

    sessionStorage.clear();
    navigate('/login');
    toast.info('Você foi desconectado.');
  };

  // Configura o axios para incluir cookies em todas as requisições
  axios.defaults.withCredentials = true;

  // Interceptor para lidar com erros de autenticação
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          handleDisconnect();
          toast.error('Sessão expirada. Faça login novamente.');
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  // Função para verificar a assinatura no backend
  const verifySignature = async (message, signature, account) => {
    try {
      const response = await axios.post(`${API_URL}/api/verifySignature`, {
        message,
        signature,
        wallet: account,
      });

      if (response.status === 200 && response.data.success) {
        console.log('Assinatura verificada com sucesso.');
        // O token JWT é armazenado em um cookie httpOnly pelo backend
        toast.success('Autenticação bem-sucedida.');
        return true;
      } else {
        toast.error('Falha na verificação da assinatura.');
        return false;
      }
    } catch (error) {
      toast.error('Erro ao verificar a assinatura.');
      return false;
    }
  };

  // Função para assinar uma mensagem para autenticação
  const signMessage = async (_signer, account) => {
    try {
      const message = 'Por favor, assine esta mensagem para confirmar sua identidade.';
      const signature = await _signer.signMessage(message);
      const success = await verifySignature(message, signature, account);
      return success;
    } catch (error) {
      toast.error('Erro ao assinar a mensagem.');
      return false;
    }
  };

  // Função para buscar o perfil do usuário no banco de dados
  const fetchUserProfile = async (walletAddress) => {
    try {
      const response = await axios.get(`${API_URL}/api/user/${walletAddress}`, {
        timeout: 5000, // Tempo limite de 5 segundos
      });
      if (response.status === 200 && response.data) {
        setUserProfile(response.data);
        toast.success('Perfil carregado com sucesso.');
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.info('Usuário não encontrado. Criando novo perfil.');
        return null;
      } else {
        toast.error('Erro ao buscar o perfil do usuário.');
        return null;
      }
    }
  };

  // Função para criar um novo perfil no banco de dados
  const createUserProfile = async (walletAddress) => {
    try {
      const uniqueEmail = `${walletAddress.toLowerCase()}@example.com`;

      const response = await axios.post(`${API_URL}/api/user`, {
        wallet: walletAddress,
        username: `User_${walletAddress.slice(0, 6)}`,
        email: uniqueEmail,
        fullName: 'Nome Completo',
        bio: 'Descrição do usuário',
        location: '',
        socialLinks: {
          twitter: '',
          discord: '',
          soundcloud: '',
          instagram: '',
          facebook: '',
        },
        preferences: {
          genres: [],
        },
        profilePicture: '',
        backgroundPicture: '',
        banner: '',
        isArtist: false,
        group: null,
        achievements: [],
        role: 'USER',
      });
      setUserProfile(response.data);
      toast.success('Perfil criado com sucesso.');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        await fetchUserProfile(walletAddress);
        toast.warn('O usuário já existe no banco de dados.');
      } else {
        toast.error('Erro ao criar o perfil do usuário.');
      }
    }
  };

  // Função para lidar com a mudança de contas
  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      handleDisconnect();
    } else {
      // Reinicializar o estado ao mudar de conta
      setProvider(null);
      setSigner(null);
      setCurrentAccount(null);
      localStorage.removeItem('currentAccount');

      const _provider = new BrowserProvider(window.ethereum);
      setProvider(_provider);
      const newSigner = await _provider.getSigner();
      setSigner(newSigner);

      const isAuthenticated = await signMessage(newSigner, accounts[0]);

      if (isAuthenticated) {
        setCurrentAccount(accounts[0]);
        localStorage.setItem('currentAccount', accounts[0]);
        localStorage.setItem('isLoggedIn', 'true');

        // Busca ou cria o perfil do usuário
        const profile = await fetchUserProfile(accounts[0]);
        if (!profile) {
          await createUserProfile(accounts[0]);
        }
      } else {
        handleDisconnect();
      }
    }
  };

  // Função para conectar a carteira
  const connectWallet = async () => {
    if (!window.ethereum) {
      toast.error('MetaMask não encontrada. Instale a MetaMask para continuar.');
      return;
    }

    try {
      const _provider = new BrowserProvider(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      if (accounts.length === 0) {
        toast.error('Nenhuma conta encontrada. Por favor, conecte sua conta MetaMask.');
        return;
      }

      setProvider(_provider);
      const _signer = await _provider.getSigner();
      setSigner(_signer);

      const isAuthenticated = await signMessage(_signer, accounts[0]);

      if (isAuthenticated) {
        setCurrentAccount(accounts[0]);
        localStorage.setItem('currentAccount', accounts[0]);
        localStorage.setItem('isLoggedIn', 'true');

        // Busca ou cria o perfil do usuário
        const profile = await fetchUserProfile(accounts[0]);
        if (!profile) {
          await createUserProfile(accounts[0]);
        }
      } else {
        handleDisconnect();
      }
    } catch (error) {
      console.error('Erro ao conectar à MetaMask:', error);
      toast.error('Erro ao conectar à MetaMask. Por favor, tente novamente.');
    }
  };

  // Função para atualizar o perfil do usuário
  const updateUserProfile = async (formDataToSend) => {
    try {
      const response = await axios.put(`${API_URL}/api/user/${currentAccount}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUserProfile(response.data);
      toast.success('Perfil atualizado com sucesso.');
    } catch (error) {
      toast.error('Erro ao atualizar o perfil do usuário.');
    }
  };

  // Função para lidar com mudanças na rede
  const handleChainChanged = () => {
    toast.info('Rede alterada. Recarregando a aplicação.');
    window.location.reload();
  };

  // Configurar listeners no useEffect
  useEffect(() => {
    if (window.ethereum && window.ethereum.on) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum && window.ethereum.removeListener) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  // Restaurar o estado ao carregar o aplicativo
  useEffect(() => {
    const account = localStorage.getItem('currentAccount');
    const isLoggedIn = localStorage.getItem('isLoggedIn');

    if (account && isLoggedIn === 'true') {
      setCurrentAccount(account);
      const reconnectWeb3 = async () => {
        const _provider = new BrowserProvider(window.ethereum);
        setProvider(_provider);
        const _signer = await _provider.getSigner();
        setSigner(_signer);
        await fetchUserProfile(account);
      };
      reconnectWeb3();
    }
  }, []);

  // Valor do contexto
  const contextValue = {
    currentAccount,
    connectWallet,
    provider,
    signer,
    userProfile,
    updateUserProfile,
  };

  return <Web3Context.Provider value={contextValue}>{children}</Web3Context.Provider>;
};
