import React, { useState, useContext, useEffect } from 'react';
import { ethers } from 'ethers';
import { Web3Context } from '../context/Web3Context';
import { getContract } from '../contracts/AccessKeyNFT';
import { motion } from 'framer-motion';
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const MintAccessKey = () => {
  const { currentAccount, signer } = useContext(Web3Context);
  const [loading, setLoading] = useState(false);
  const mintPrice = ethers.parseEther('0.01');
  const [hasAccessKey, setHasAccessKey] = useState(false);
  const [totalKeysMinted, setTotalKeysMinted] = useState('0');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [activeTab, setActiveTab] = useState('mint');

  const checkIfUserHasKey = async () => {
    try {
      const contract = getContract(signer);
      const balance = await contract.balanceOf(currentAccount);
      setHasAccessKey(balance > 0);
    } catch (error) {
      console.error('Erro ao verificar chave de acesso:', error);
    }
  };

  const getTotalKeysMinted = async () => {
    try {
      const contract = getContract(signer);
      const totalSupply = await contract.totalSupply();
      setTotalKeysMinted(totalSupply.toString());
    } catch (error) {
      console.error('Erro ao obter total de chaves cunhadas:', error);
    }
  };

  useEffect(() => {
    if (signer && currentAccount) {
      checkIfUserHasKey();
      getTotalKeysMinted();
    }
  }, [signer, currentAccount]);

  const handleMint = async () => {
    if (!currentAccount) {
      setMessage('Conecte sua carteira primeiro.');
      setMessageType('error');
      return;
    }

    if (hasAccessKey) {
      setMessage('Você já possui uma Chave de Acesso.');
      setMessageType('error');
      return;
    }

    try {
      setLoading(true);
      const contract = getContract(signer);
      const tx = await contract.mintAccessKey({ value: mintPrice });
      await tx.wait();

      setMessage('Chave de acesso cunhada com sucesso!');
      setMessageType('success');
      setHasAccessKey(true);
      getTotalKeysMinted();
    } catch (error) {
      console.error('Erro ao cunhar NFT:', error);
      setMessage('Erro ao cunhar NFT: ' + (error.reason || error.message));
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Adquira Sua Chave de Acesso | DOGMA</title>
        <meta
          name="description"
          content="Adquira sua chave de acesso exclusiva e desbloqueie benefícios especiais na plataforma DOGMA."
        />
      </Helmet>

      <div className="relative bg-[#121212] min-h-screen text-white flex flex-col items-center py-20 px-8 font-sans">
        <div className="w-full max-w-4xl">
          <div className="flex justify-center mb-8">
            <button
              className={`px-6 py-3 rounded-t-lg font-semibold text-xl transition-colors duration-300 ${
                activeTab === 'mint' ? 'bg-[#1DB954] text-[#121212]' : 'bg-[#181818] text-white'
              }`}
              onClick={() => setActiveTab('mint')}
            >
              Cunhar Chave de Acesso
            </button>
            <button
              className={`px-6 py-3 rounded-t-lg font-semibold text-xl transition-colors duration-300 ${
                activeTab === 'benefits' ? 'bg-[#1DB954] text-[#121212]' : 'bg-[#181818] text-white'
              }`}
              onClick={() => setActiveTab('benefits')}
            >
              Benefícios
            </button>
            <button
              className={`px-6 py-3 rounded-t-lg font-semibold text-xl transition-colors duration-300 ${
                activeTab === 'messages' ? 'bg-[#1DB954] text-[#121212]' : 'bg-[#181818] text-white'
              }`}
              onClick={() => setActiveTab('messages')}
            >
              Mensagens
            </button>
          </div>

          <div className="bg-[#181818] p-16 rounded-3xl shadow-2xl backdrop-blur-lg border border-[#1DB954] w-full text-center">
            {activeTab === 'mint' && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
              >
                <h1 className="text-6xl font-extrabold text-[#1DB954] mb-8 drop-shadow-lg">
                  Adquira Sua Chave de Acesso
                </h1>
                <p className="text-xl text-gray-400 mb-8">
                  Desbloqueie benefícios exclusivos e faça parte da comunidade DOGMA.
                </p>

                {currentAccount ? (
                  hasAccessKey ? (
                    <p className="text-green-400 text-2xl font-semibold">
                      Você já possui uma Chave de Acesso.
                    </p>
                  ) : (
                    <motion.button
                      onClick={handleMint}
                      className={`w-full py-4 bg-gradient-to-r from-green-500 to-green-600 text-white font-semibold rounded-full shadow-md hover:from-green-400 hover:to-green-500 transition duration-300 flex items-center justify-center ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={loading}
                      whileHover={{ scale: 1.05 }}
                    >
                      {loading ? (
                        <>
                          <FaSpinner className="animate-spin mr-2" />
                          Cunhando...
                        </>
                      ) : (
                        'Cunhar Chave de Acesso (0.01 ETH)'
                      )}
                    </motion.button>
                  )
                ) : (
                  <p className="text-red-500 text-xl">Conecte sua carteira para cunhar.</p>
                )}

                {message && (
                  <div
                    className={`mt-4 p-4 rounded flex items-center justify-center ${
                      messageType === 'success' ? 'bg-green-500' : 'bg-red-500'
                    }`}
                  >
                    {messageType === 'success' ? (
                      <FaCheckCircle className="mr-2 text-white" />
                    ) : (
                      <FaExclamationCircle className="mr-2 text-white" />
                    )}
                    <p>{message}</p>
                  </div>
                )}

                <p className="text-gray-400 mt-6 text-xl">
                  Total de Chaves de Acesso cunhadas: {totalKeysMinted}
                </p>
              </motion.div>
            )}

            {activeTab === 'benefits' && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
              >
                <h2 className="text-5xl font-extrabold text-[#1DB954] mb-8 drop-shadow-lg">
                  Por que Cunhar a Chave de Acesso?
                </h2>
                <ul className="list-none text-left space-y-4 text-gray-300 text-xl">
                  <li>• <span className="text-[#1DB954] font-bold">Eventos Exclusivos:</span> Acesso garantido a eventos e festivais exclusivos da comunidade DOGMA.</li>
                  <li>• <span className="text-[#1DB954] font-bold">Benefícios VIP:</span> Experiências diferenciadas e acesso antecipado a novos recursos da plataforma.</li>
                  <li>• <span className="text-[#1DB954] font-bold">Interação com Artistas:</span> Participe de sessões exclusivas e conecte-se com artistas e criadores de conteúdo.</li>
                  <li>• <span className="text-[#1DB954] font-bold">Merchandising Exclusivo:</span> Obtenha acesso a itens de edição limitada, incluindo camisetas e acessórios da marca DOGMA.</li>
                  <li>• <span className="text-[#1DB954] font-bold">Royalties:</span> Receba royalties em eventos e colabore na criação de novos projetos dentro da comunidade.</li>
                </ul>
              </motion.div>
            )}

            {activeTab === 'messages' && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5 }}
              >
                <h2 className="text-5xl font-extrabold text-[#1DB954] mb-8 drop-shadow-lg">
                  Mensagens
                </h2>
                <p className="text-xl text-gray-400 mb-8">
                  Veja mensagens importantes sobre a cunhagem de sua Chave de Acesso e atualizações da comunidade.
                </p>
                <div className="bg-[#1E1E1E] p-6 rounded-lg shadow-md text-left text-gray-300">
                  <p>• Não se esqueça de conectar sua carteira antes de cunhar a Chave de Acesso.</p>
                  <p>• A Chave de Acesso permite participar de eventos exclusivos e interagir com artistas da comunidade.</p>
                  <p>• Fique atento a futuras atualizações para mais benefícios!</p>
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MintAccessKey;