import React from 'react';
import { FaRegCalendarAlt, FaRocket, FaHeadphones, FaStar } from 'react-icons/fa';

const CrewHome = () => {
  return (
    <div className="crew-home-container w-full h-full bg-gradient-to-b from-[#121212] to-[#181818] text-white flex flex-col justify-center items-center p-8 rounded-lg shadow-lg space-y-12">
      {/* Banner */}
      <section className="text-center">
        <h1 className="text-6xl font-extrabold text-[#1DB954] mb-4">Registre sua Crew ou Gravadora</h1>
        <p className="text-lg text-gray-300 max-w-3xl mx-auto">
          Crie sua crew ou gravadora e tenha visibilidade no universo da música eletrônica. 
          Organize eventos, colabore com artistas e faça sua crew crescer na plataforma mais inovadora.
        </p>
        <button className="mt-8 bg-[#1DB954] hover:bg-[#17a54a] text-black font-semibold py-4 px-10 rounded-full transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105">
          Registre sua Crew Agora
        </button>
      </section>

      {/* Ícones e Benefícios */}
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center">
        <div className="feature-item bg-[#1C1C1C] p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 hover:bg-[#262626]">
          <FaRocket className="text-[#1DB954] text-6xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-white">Impulsione Sua Crew</h3>
          <p className="text-gray-400 mt-2">
            Ganhe visibilidade na plataforma e conecte-se com outros artistas e gravadoras.
          </p>
        </div>
        <div className="feature-item bg-[#1C1C1C] p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 hover:bg-[#262626]">
          <FaRegCalendarAlt className="text-[#1DB954] text-6xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-white">Crie Eventos Futuramente</h3>
          <p className="text-gray-400 mt-2">
            Organize seus próprios eventos e envolva sua comunidade em experiências únicas.
          </p>
        </div>
        <div className="feature-item bg-[#1C1C1C] p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 hover:bg-[#262626]">
          <FaHeadphones className="text-[#1DB954] text-6xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-white">Colabore com Artistas</h3>
          <p className="text-gray-400 mt-2">
            Trabalhe em conjunto com outros membros e artistas para criar algo incrível.
          </p>
        </div>
        <div className="feature-item bg-[#1C1C1C] p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 hover:bg-[#262626]">
          <FaStar className="text-[#1DB954] text-6xl mx-auto mb-4" />
          <h3 className="text-2xl font-semibold text-white">Destaque-se na Plataforma</h3>
          <p className="text-gray-400 mt-2">
            Receba destaque e reconhecimento na plataforma como uma crew influente.
          </p>
        </div>
      </section>

      {/* Sessão de Exploração */}
      <section className="bg-[#1C1C1C] p-8 rounded-lg shadow-md w-full max-w-5xl text-center">
        <h2 className="text-3xl font-bold text-white mb-6">Por que criar uma Crew ou Gravadora?</h2>
        <p className="text-lg text-gray-300 mb-6">
          Ao registrar sua crew ou gravadora, você ganha uma presença sólida dentro da comunidade e 
          tem a oportunidade de expandir seu público, organizar eventos e colaborar com artistas em uma plataforma inovadora.
        </p>
        <div className="flex justify-center space-x-4">
          <button className="bg-[#1DB954] hover:bg-[#17a54a] text-black font-semibold py-3 px-8 rounded-full transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105">
            Explorar Crews
          </button>
          <button className="bg-[#333] text-white font-semibold py-3 px-8 rounded-full transition-all duration-300 shadow-lg hover:shadow-2xl hover:bg-[#444] transform hover:scale-105">
            Ver Gravadoras
          </button>
        </div>
      </section>
    </div>
  );
};

export default CrewHome;
