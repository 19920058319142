import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { FaCheck, FaArrowRight, FaArrowLeft, FaCamera } from 'react-icons/fa';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CrewForm = ({ currentAccount, connectWallet, fetchCrews, switchTab }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    imageFile: null,
    imageUrl: '', // Campo para o link de imagem
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  // Função para lidar com a mudança de inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Função para lidar com o upload de imagem
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        imageFile: file,
        imageUrl: '', // Reset imageUrl quando file for selecionado
      }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  // Função para lidar com a inserção de link direto
  const handleImageUrlChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      imageUrl: value,
      imageFile: null, // Reset imageFile quando imageUrl for inserido
    }));
    setPreviewImage(value);
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // Função para enviar os dados ao backend
  const onSubmit = async () => {
    setLoading(true);
    const crewData = new FormData();
    crewData.append('name', formData.name);
    crewData.append('description', formData.description);

    // Verifica se o usuário fez upload ou inseriu um link
    if (formData.imageFile) {
      crewData.append('imageFile', formData.imageFile); // Upload de arquivo de imagem
    } else if (formData.imageUrl) {
      crewData.append('imageUrl', formData.imageUrl); // Link direto da imagem
    }

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('Usuário não autenticado.');
        setLoading(false);
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/group`, crewData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      

      if (response.status === 201) {
        setSuccess(true);
        fetchCrews(); // Atualiza a lista de crews
        setTimeout(() => {
          switchTab('home'); // Redireciona para o CrewHome
        }, 2000);
      }
    } catch (error) {
      console.error('Erro ao criar a crew:', error);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  // Busca o nome de usuário baseado na carteira
  useEffect(() => {
    const fetchUsername = async () => {
      if (currentAccount) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${currentAccount}`);
          setUsername(response.data.username || currentAccount);
        } catch (error) {
          console.error('Erro ao buscar o nome de usuário:', error);
          setUsername(currentAccount);
        }
      }
    };
    fetchUsername();
  }, [currentAccount]);
  

  // Etapa 1: Escolha do Nome
  const Step1 = () => (
    <div className="step-container text-center p-6 transition-opacity duration-500 ease-in-out">
      <h2 className="text-3xl font-bold text-[#1DB954] mb-6">Aqui vai brilhar!</h2>
      <p className="text-lg text-gray-400 mb-4">Escolha um nome épico para sua Crew. Este será o nome pelo qual todos conhecerão sua comunidade!</p>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        placeholder="Nome da Crew"
        className="bg-gray-800 text-white w-full p-3 rounded-lg"
      />
      <button 
        onClick={nextStep} 
        disabled={!formData.name.trim()} 
        className={`mt-6 ${formData.name.trim() ? 'bg-[#1DB954] hover:scale-105' : 'bg-gray-600'} text-black px-6 py-3 rounded-full transition-all`}>
        Próximo <FaArrowRight className="inline ml-2" />
      </button>
    </div>
  );

  // Etapa 2: Descrição
  const Step2 = () => (
    <div className="step-container text-center p-6 transition-opacity duration-500 ease-in-out">
      <h2 className="text-3xl font-bold text-[#1DB954] mb-6">Dê vida à sua Crew!</h2>
      <p className="text-lg text-gray-400 mb-4">Descreva o propósito e o impacto da sua Crew. Deixe claro o que a torna especial!</p>
      <textarea
        name="description"
        value={formData.description}
        onChange={handleInputChange}
        placeholder="Insira uma descrição incrível"
        rows="5"
        className="bg-gray-800 text-white w-full p-3 rounded-lg"
      ></textarea>
      <div className="flex justify-between mt-6">
        <button onClick={prevStep} className="bg-gray-500 text-white px-6 py-3 rounded-full hover:scale-105 transform transition-all">
          <FaArrowLeft className="inline mr-2" /> Voltar
        </button>
        <button 
          onClick={nextStep} 
          disabled={!formData.description.trim()} 
          className={`bg-${formData.description.trim() ? '[#1DB954]' : 'gray-600'} text-black px-6 py-3 rounded-full hover:scale-105 transform transition-all`}>
          Próximo <FaArrowRight className="inline ml-2" />
        </button>
      </div>
    </div>
  );

  // Etapa 3: Upload de Imagem ou Link Direto
  const Step3 = () => (
    <div className="step-container text-center p-6 transition-opacity duration-500 ease-in-out">
      <h2 className="text-3xl font-bold text-[#1DB954] mb-6">Agora, escolha uma imagem!</h2>
      <p className="text-lg text-gray-400 mb-4">Uma imagem vale mais que mil palavras. Escolha uma foto incrível para representar sua Crew.</p>
      <div
        className="relative w-48 h-48 rounded-full bg-gray-800 mx-auto mb-6 overflow-hidden shadow-lg cursor-pointer group"
        onClick={() => document.getElementById('crewImageInput').click()}
      >
        {previewImage ? (
          <img src={previewImage} alt="Preview" className="w-full h-full object-cover" />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-400">Nenhuma imagem selecionada</div>
        )}
        <input
          type="file"
          id="crewImageInput"
          className="hidden"
          accept="image/*"
          onChange={handleImageChange}
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
          <FaCamera className="text-white text-3xl opacity-80" />
        </div>
      </div>
      <p className="text-lg text-gray-400 mb-4">Ou insira o link direto de uma imagem/GIF:</p>
      <input
        type="text"
        name="imageUrl"
        value={formData.imageUrl}
        onChange={handleImageUrlChange}
        placeholder="Link direto da imagem ou GIF"
        className="bg-gray-800 text-white w-full p-3 rounded-lg"
      />
      <div className="flex justify-between mt-6">
        <button onClick={prevStep} className="bg-gray-500 text-white px-6 py-3 rounded-full hover:scale-105 transform transition-all">
          <FaArrowLeft className="inline mr-2" /> Voltar
        </button>
        <button onClick={nextStep} className="bg-[#1DB954] text-black px-6 py-3 rounded-full hover:scale-105 transform transition-all">
          Próximo <FaArrowRight className="inline ml-2" />
        </button>
      </div>
    </div>
  );

  // Etapa Final: Revisão
  const StepFinal = () => (
    <div className="step-container text-center p-6 transition-opacity duration-500 ease-in-out">
      <h2 className="text-3xl font-bold text-[#1DB954] mb-6">Quase lá!</h2>
      <p className="text-lg text-gray-400 mb-4">Revise sua Crew antes de finalizar. Certifique-se de que todos os detalhes estão perfeitos.</p>
      <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
        <h3 className="text-2xl text-[#1DB954]">{formData.name}</h3>
        <p className="text-gray-400">{formData.description}</p>
        {previewImage && <img src={previewImage} alt="Preview" className="w-48 h-48 mx-auto mt-4 rounded-full" />}
      </div>
      <div className="flex justify-between mt-6">
        <button onClick={prevStep} className="bg-gray-500 text-white px-6 py-3 rounded-full hover:scale-105 transform transition-all">
          <FaArrowLeft className="inline mr-2" /> Voltar
        </button>
        <button onClick={onSubmit} className="bg-[#1DB954] text-black px-6 py-3 rounded-full hover:scale-105 transform transition-all">
          {loading ? <PulseLoader size={8} color="#fff" /> : <>Criar Crew <FaCheck className="inline ml-2" /></>}
        </button>
      </div>
      {success === true && <p className="text-green-500 text-center mt-4">Crew criada com sucesso!</p>}
      {success === false && <p className="text-red-500 text-center mt-4">Erro ao criar a crew. Tente novamente.</p>}
    </div>
  );

  return (
    <div className="crew-form-steps bg-[#1C1C1C] p-6 rounded-lg shadow-lg">
      {/* Mensagem de Boas-vindas com texto à esquerda */}
      <div className="welcome-message text-left mb-8">
        <h2 className="text-4xl font-bold text-[#1DB954]">Olá, {username || 'Artista'}!</h2>
        <p className="text-lg text-gray-400">Seja bem-vindo ao Gerenciador de Crews. Vamos começar sua jornada épica!</p>
      </div>

      {/* Controle das Etapas */}
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}
      {step === 3 && <Step3 />}
      {step === 4 && <StepFinal />}
    </div>
  );
};

export default CrewForm;
