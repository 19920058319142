import React, { useContext, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaArrowLeft, FaTrash, FaCheck, FaReply, FaTimes } from 'react-icons/fa';
import { Web3Context } from '../../context/Web3Context';

const EmailDetails = ({
  selectedEmail,
  handleBackToInbox,
  handleDeleteEmail,
  handleMarkAsRead,
  handleSendEmail,
}) => {
  const { userProfile } = useContext(Web3Context);
  const [isReplying, setIsReplying] = useState(false);
  const [replyMessage, setReplyMessage] = useState({
    receiver: selectedEmail.sender,
    subject: `RE: ${selectedEmail.subject}`,
    body: '',
  });

  // Estados para o modal e a imagem atual
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const handleReplyChange = (e) => {
    setReplyMessage({ ...replyMessage, [e.target.name]: e.target.value });
  };

  const handleSendReply = () => {
    handleSendEmail(replyMessage);
    setIsReplying(false);
  };

  // Fechar o modal ao pressionar a tecla 'Esc'
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isModalOpen) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isModalOpen]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-4"
    >
      <button
        onClick={handleBackToInbox}
        className="text-gray-300 hover:text-green-400 transition duration-300 mb-4 flex items-center"
      >
        <FaArrowLeft className="mr-2" /> Voltar ao Inbox
      </button>

      <div className="flex items-center mb-4">
        <img
          src={userProfile?.profilePicture || 'https://via.placeholder.com/50'}
          alt="Remetente"
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h2 className="text-lg font-bold text-white">
            {userProfile?.displayName || userProfile?.username || 'Usuário Desconhecido'}
          </h2>
          <p className="text-sm text-gray-400">{selectedEmail.sender || 'Remetente desconhecido'}</p>
        </div>
      </div>

      <div className="bg-gray-700 p-4 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-green-400 mb-2">
          {selectedEmail.subject || 'Sem título'}
        </h2>
        <p className="text-gray-300 mb-4 whitespace-pre-wrap">{selectedEmail.body || 'Sem conteúdo'}</p>

        {/* Exibir Anexos */}
        {selectedEmail.attachments && selectedEmail.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-bold text-white mb-2">Anexos</h3>
            <div className="flex flex-wrap">
              {selectedEmail.attachments.map((attachment, index) => (
                <motion.img
                  key={index}
                  src={attachment}
                  alt={`Anexo ${index + 1}`}
                  className="w-32 h-32 object-cover rounded-lg m-2 cursor-pointer shadow-md hover:shadow-xl transition-shadow duration-300"
                  onClick={() => {
                    setCurrentImage(attachment);
                    setIsModalOpen(true);
                  }}
                  whileHover={{ scale: 1.05 }}
                />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end mt-4 space-x-4">
        <button
          onClick={() => handleMarkAsRead(selectedEmail._id)}
          className="text-green-400 hover:text-green-600 transition duration-300"
          aria-label="Marcar como lido"
        >
          <FaCheck size={20} />
        </button>
        <button
          onClick={() => handleDeleteEmail(selectedEmail._id)}
          className="text-red-400 hover:text-red-600 transition duration-300"
          aria-label="Excluir e-mail"
        >
          <FaTrash size={20} />
        </button>
        <button
          onClick={() => setIsReplying(!isReplying)}
          className="text-blue-400 hover:text-blue-600 transition duration-300"
          aria-label="Responder"
        >
          <FaReply size={20} />
        </button>
      </div>

      {isReplying && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mt-4 bg-gray-800 p-4 rounded-lg"
        >
          <h3 className="text-lg font-bold text-white mb-2">Responder</h3>
          <div className="mb-4">
            <label className="block text-gray-400">Para</label>
            <input
              type="text"
              name="receiver"
              value={replyMessage.receiver}
              onChange={handleReplyChange}
              className="w-full p-2 rounded-lg bg-gray-700 text-white"
              readOnly
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">Assunto</label>
            <input
              type="text"
              name="subject"
              value={replyMessage.subject}
              onChange={handleReplyChange}
              className="w-full p-2 rounded-lg bg-gray-700 text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">Mensagem</label>
            <textarea
              name="body"
              value={replyMessage.body}
              onChange={handleReplyChange}
              className="w-full p-2 rounded-lg bg-gray-700 text-white"
              rows="6"
            ></textarea>
          </div>
          <button
            onClick={handleSendReply}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
          >
            Enviar Resposta
          </button>
        </motion.div>
      )}

      {/* Modal para exibir a imagem em tamanho completo */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsModalOpen(false)}
          >
            <motion.div
              className="relative"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={currentImage}
                alt="Imagem ampliada"
                className="max-w-full max-h-screen rounded-lg shadow-lg"
              />
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-white text-3xl font-bold focus:outline-none"
                aria-label="Fechar"
              >
                <FaTimes />
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default EmailDetails;
