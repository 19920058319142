import React, { memo, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { Web3Context } from '../context/Web3Context';

import { getContract } from '../contracts/AccessKeyNFT';

import axios from 'axios';
import io from 'socket.io-client';
import MessageList from './chat-module/MessageList';
import MessageInput from './chat-module/MessageInput';
import UserProfilePopup from './chat-module/UserProfilePopup';
import LoadingScreen from './chat-module/LoadingScreen';
import ShakaPlayer from './ShakaPlayer';


const Chat = () => {
  const { currentAccount, userProfile, signer } = useContext(Web3Context);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [userTyping, setUserTyping] = useState(null);
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [socket, setSocket] = useState(null); // Estado para o socket
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const messageBoxRef = useRef(null);
  const API_CHAT = process.env.REACT_APP_API_CHAT_URL;
  const API_URL = process.env.REACT_APP_API_URL;

  const checkIfUserHasKey = useCallback(async () => {
    try {
      const contract = getContract(signer);
      const balance = await contract.balanceOf(currentAccount);
      const hasUserAccess = balance > 0;
      setHasAccess(hasUserAccess);

      setTimeout(() => {
        setIsLoading(false);
        if (hasUserAccess) {
          window.scrollTo({
            top: (document.body.scrollHeight - window.innerHeight) / 7,
            behavior: 'smooth',
          });
        }
      }, 7500);
    } catch (error) {
      console.error('Erro ao verificar chave de acesso:', error);
      setError('Não foi possível verificar o NFT.');
      setIsLoading(false);
    }
  }, [signer, currentAccount]);

  useEffect(() => {
    if (signer && currentAccount) {
      checkIfUserHasKey();
    }
  }, [signer, currentAccount, checkIfUserHasKey]);

  useEffect(() => {
    if (hasAccess) {
      const fetchMessages = async () => {
        try {
          const response = await axios.get(`${API_CHAT}/messages`);
          setMessages(response.data);
        } catch (error) {
          console.error('Erro ao carregar mensagens:', error);
          setError('Não foi possível carregar as mensagens.');
        }
      };
      fetchMessages();
    }
  }, [hasAccess]);

  useEffect(() => {
    if (hasAccess) {
      // Inicializa o socket apenas quando o usuário tem acesso
      const newSocket = io(process.env.REACT_APP_API_URL);
      setSocket(newSocket);

      newSocket.on('message', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      newSocket.on('typing', (user) => {
        setUserTyping(user);
        setTimeout(() => setUserTyping(null), 2000);
      });

      // Cleanup para desconectar o socket ao desmontar
      return () => {
        newSocket.off('message');
        newSocket.off('typing');
        newSocket.disconnect();
      };
    }
  }, [hasAccess]);

  const sendMessage = () => {
    if (inputMessage.trim() && currentAccount && userProfile) {
      const newMessage = {
        user: `${userProfile.username} [${currentAccount}]`,
        text: inputMessage,
        timestamp: new Date(),
      };
      socket.emit('message', newMessage);
      setInputMessage('');
    }
  };

  const handleTyping = () => {
    if (currentAccount && userProfile) {
      socket.emit('typing', userProfile.username);
    }
  };

  const handleUserClick = useCallback(async (user) => {
    const walletAddress = user.match(/\[(.*?)\]/)?.[1];
    if (!walletAddress) return;

    try {
      const response = await axios.get(`${API_URL}api/user/${walletAddress}`);
      setSelectedUserProfile(response.data);
    } catch (error) {
      console.error('Erro ao carregar perfil do usuário:', error);
      setError('Não foi possível carregar o perfil do usuário.');
    }
  }, [API_URL]);

  const closeUserProfilePopup = useCallback(() => {
    setSelectedUserProfile(null);
  }, []);

  if (isLoading) {
    return <LoadingScreen hasAccess={hasAccess} />;
  }

  if (!hasAccess) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#121212]">
        <div className="text-center">
          <h2 className="text-3xl text-[#1DB954] mb-4">Acesso Negado</h2>
          <p className="text-gray-400">
            Você não possui a chave de acesso necessária para visualizar este conteúdo.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen bg-[#121212]">
      <div className="flex-1 flex items-center justify-center bg-black">
        <ShakaPlayer src="https://167.114.5.194:8080/hls/stream.m3u8" />
      </div>

      <div className="lg:w-1/4 w-full bg-[#282828] p-4 overflow-hidden h-full flex flex-col shadow-lg">
        <h2 className="text-3xl font-bold mb-4 text-center text-[#1DB954] text-shadow-black-100 border-b border-gray-700 pb-2">
          Chat da Transmissão
        </h2>

        {error && <div className="text-red-500 text-center mb-2">{error}</div>}

        <div className="flex-grow overflow-y-auto">
          <MessageList
            messages={messages}
            currentAccount={currentAccount}
            handleUserClick={handleUserClick}
            messageBoxRef={messageBoxRef}
          />
        </div>
        <div className="flex-none mt-2">
          <MessageInput
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            sendMessage={sendMessage}
            handleTyping={handleTyping}
          />
        </div>
      </div>

      <UserProfilePopup
        selectedUserProfile={selectedUserProfile}
        closeUserProfilePopup={closeUserProfilePopup}
      />
    </div>
  );
};

export default memo(Chat);
