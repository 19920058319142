import React from 'react';
import { motion } from 'framer-motion';

const InboxTooltip = ({ messages, handleSelectMessage, inboxRef }) => {
  // Filtrar apenas mensagens não lidas
  const unreadMessages = messages.filter(message => !message.isRead);

  return (
    <motion.div
      ref={inboxRef}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="absolute right-0 mt-2 w-80 bg-[#121212] bg-opacity-90 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg overflow-hidden z-50 border border-[#282828]"
    >
      <div className="p-4">
        <h3 className="text-[#1DB954] text-lg font-bold mb-2">Mensagens</h3>
        {unreadMessages.length > 0 ? ( // Verifique se há mensagens não lidas
          <ul>
            {unreadMessages.slice(0, 5).map((message) => ( // Limitar a 5 mensagens não lidas
              <li
                key={message._id}
                onClick={() => handleSelectMessage(message._id)}
                className="flex items-start p-2 cursor-pointer hover:bg-[#1DB954] hover:bg-opacity-20 transition duration-200 rounded-md"
                role="button"
                aria-label={`Selecionar mensagem: ${message.subject}`}
              >
                <div className="flex-grow">
                  <p className="text-white font-medium">{message.subject}</p>
                  <p className="text-gray-400 text-sm">
                    {message.body.length > 50 ? `${message.body.substring(0, 50)}...` : message.body}
                  </p>
                </div>
                <span className="ml-2 text-[#1DB954] font-bold text-xs">Novo</span>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">Sem novas mensagens.</p>
        )}
        <div className="mt-2 text-center">
          <button
            onClick={() => handleSelectMessage(null)}
            className="text-[#1DB954] hover:text-green-500 transition duration-300"
            aria-label="Ver todas as mensagens"
          >
            Ver todas as mensagens
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default InboxTooltip;