import { useState, useEffect } from 'react';
import axios from 'axios';

const useLikes = (tokenId, currentAccount) => {
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(false); // Estado para bloquear o botão

  useEffect(() => {
    // Se tokenId não estiver presente, não faz a chamada
    if (tokenId === null || tokenId === undefined) return;

    const fetchLikes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/like/${tokenId}`);
        setLikes(response.data.likes);
    
        if (currentAccount) {
          const checkResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/like/check/${tokenId}/${currentAccount}`);
          setLiked(checkResponse.data.liked);
        }
      } catch (error) {
        console.error('Erro ao carregar likes:', error);
      }
    };
    

    fetchLikes();
  }, [tokenId, currentAccount]);

  const toggleLike = async () => {
    if (loading) return; // Evita que o usuário flode o like/unlike
    setLoading(true);

    console.log('Enviando requisição de like com os seguintes dados:', {
      nftId: tokenId,
      userId: currentAccount,
    });

    try {
      if (liked) {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/like/unlike`, {
          nftId: tokenId,
          userId: currentAccount,
        });
        setLikes((prev) => Math.max(prev - 1, 0)); // Garante que likes não fiquem negativos
        setLiked(false);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/like`, {
          nftId: tokenId,
          userId: currentAccount,
        });
        setLikes((prev) => prev + 1);
        setLiked(true);
      }
    } catch (error) {
      console.error('Erro ao alternar like:', error);
    } finally {
      // Remove o bloqueio após um curto período para evitar spam
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Tempo em ms que o botão fica bloqueado (1 segundo)
    }
  };

  return { likes, liked, toggleLike, loading };
};

export default useLikes;
